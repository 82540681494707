import {createApp} from 'vue';
import App from './App.vue';
import vClickOutside from 'click-outside-vue3';
import router from './router';
import store from './store';
import './assets/css/app.css';
import 'vue-toastification/dist/index.css';
import {applyPolyfills, defineCustomElements} from '@aws-amplify/ui-components/loader';
import Toast from 'vue-toastification';
import Breadcrumb from '@/components/Breadcrumb';
import Topbar from '@/components/Topbar';
import excel from "vue-excel-export";

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});
const options = {
  // You can set your default options here
};
createApp(App)
  .use(excel)
  .use(router)
  .use(store)
  .use(Toast, options)
  .use(vClickOutside)
  .component('Breadcrumb', Breadcrumb)
  .component('Topbar', Topbar)
  .mount('#app');
