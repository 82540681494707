import {createRouter, createWebHashHistory} from 'vue-router';
import routes from './routes';
import store from '@/store';

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const loggedIn = store.getters['auth/loggedIn'];

  // Handle auth redirection
  if (to.matched.some(record => record.meta.requiresAuth === false)) {
    if (loggedIn) {
      return next({name: 'home'});
    }
  } else if (!loggedIn) {
    return next({name: 'auth.login', query: {redirect: to.fullPath}});
  }

  return next();
});

export default router;
