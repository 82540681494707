export default [
  {
    path: '/auth',
    meta: {requiresAuth: false},
    component: () => import('@/layouts/Auth'),
    children: [
      {
        path: 'login',
        name: 'auth.login',
        component: () => import('@/views/Auth/Login'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/App'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/Home'),
      },
      {
        path: 'maps',
        name: 'maps',
        component: () => import('@/views/Home/MapIndex'),
      },
      {
        path: 'surveys',
        name: 'surveys',
        component: () => import('@/views/Surveys'),
      },
      {
        path: 'surveys/:id/edit',
        name: 'surveys.edit',
        component: () => import('@/views/Surveys/Edit'),
      },
      {
        path: 'surveys/:id/results',
        name: 'surveys.results',
        component: () => import('@/views/Surveys/newShow'),
        // component: () => import('@/views/Surveys/Show'),
      },
      {
        path: 'surveys/:id/results/:result',
        name: 'surveys.view',
        component: () => import('@/views/Surveys/View'),
      },
      {
        path: 'surveys/:id/downloads',
        name: 'surveys.downloads',
        component: () => import('@/views/Surveys/Downloads'),
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/Users'),
      },
      {
        path: 'users/create',
        name: 'users.create',
        component: () => import('@/views/Users/Create'),
      },
    ],
  },
];
