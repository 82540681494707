<template>
  <router-view />
</template>

<script>
import API from '@aws-amplify/api';
import awsmobile from './aws-exports.js';

API.configure(awsmobile);

export default {
  name: 'App',
  components: {},
  data() {
    return {
      isLoading: true,
    };
  },
  async created() {
    // await DataStore.start();
  },
};
</script>
