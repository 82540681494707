export const filters = [
  'district:Jaffna',
  'district:Mannar',
  'district:Vavuniya',
  'district:Mullaitivu',
  'district:Kilinochchi',
  'ds:Island_North-Kayts',
  'ds:Valikamam_West-Chankanai',
  'ds:Valikamam_South-West-Sandilipay',
  'ds:Valikamam_North-Tellipallai',
  'ds:Valikamam_South-Uduvil',
  'ds:Valikamam-East-_Kopayi',
  'ds:Vadamaradchy_South-West,_Karaveddy',
  'ds:Vadamaradchi_East-Maruthnkerny',
  'ds:Vadamaradchi_North-Point_Pedro',
  'ds:Thenmaradchi-Chavakachcheri',
  'ds:Nallur',
  'ds:Jaffna',
  'ds:Island_South_,Velanai',
  'ds:Delft',
  'ds:Karainagar',
  'ds:Mannar_Town',
  'ds:Manthai_West',
  'ds:Madhu',
  'ds:Nanaddan',
  'ds:Musalai',
  'ds:Vavuniya_North',
  'ds:Vavuniya_South',
  'ds:Vavuniya',
  'ds:Vengalacheddikulam',
  'ds:Thunukkai',
  'ds:Manthai_East',
  'ds:Puthukudiyiruppu',
  'ds:Oddusuddan',
  'ds:Maritimepattu',
  'ds:Welioya',
  'ds:Pachchilaipalli',
  'ds:Kandavalai',
  'ds:Karachchi',
  'ds:Poonakary',
  'gn:Analaitivu_North-J/37',
  'gn:Analaitivu_South-J/38',
  'gn:Eluvaitivu-J/39',
  'gn:Kayts-J/49',
  'gn:Paruthiyadaippu-J/50',
  'gn:Karampon-J/51',
  'gn:Karampon_East-J/52',
  'gn:Karampon_South_East-J/53',
  'gn:Karampon_West-J/54',
  'gn:Naranthanai_North-J/55',
  'gn:Naranthanai_North_West-J/56',
  'gn:Naranthanai-J/57',
  'gn:Naranthanai_North_West-J/58',
  'gn:Seruvil-J/59',
  'gn:Puliyankoodal-J/60',
  'gn:Vaddukoddai_East-J/157',
  'gn:Vaddukoddai_North-J/158',
  'gn:Sangaraththai-J/159',
  'gn:Araly_West-J/160',
  'gn:Araly_Centre-J/161',
  'gn:Araly_South-J/162',
  'gn:Araly_East-J/163',
  'gn:Araly_North-J/164',
  'gn:Vaddu_South-J/165',
  'gn:Vaddukoddai_South_West-J/166',
  'gn:Vaddukoddai_West-J/167',
  'gn:Tholpuram_East-J/168',
  'gn:Tholpuram_West-J/169',
  'gn:Ponnalai-J/170',
  'gn:Moolai-J/171',
  'gn:Chulipuram_West-J/172',
  'gn:Chulipuram_Centre-J/173',
  'gn:Chulipuram_East-J/174',
  'gn:Pannakam-J/175',
  'gn:Panipulam-J/176',
  'gn:Sithankerny-J/177',
  'gn:Chankanai_East-J/178',
  'gn:Chankanai_West-J/179',
  'gn:Chankanai_South-J/180',
  'gn:Chankanai_Centre-J/181',
  'gn:Suthumalai_North-J/129',
  'gn:Suthumalai_South-J/130',
  'gn:Savatkaddu-J/131',
  'gn:Uyarapulam-J/132',
  'gn:Anaikoddadai-J/133',
  'gn:Navali_North-J/134',
  'gn:Navali_East-J/135',
  'gn:Navali_South-J/136',
  'gn:Manipay_North-J/137',
  'gn:Manipay_East-J/138',
  'gn:Manipay_South-J/139',
  'gn:Manipay_West-J/140',
  'gn:Sandilipay_North-J/141',
  'gn:Sandilipay_Centre-J/142',
  'gn:Sandilipay_West-J/143',
  'gn:Mahiyapiddy-J/144',
  'gn:Vadaliyadaippu-J/145',
  'gn:Pandaitharippu-J/146',
  'gn:Piranpattu-J/147',
  'gn:Chillalai_North-J/148',
  'gn:Chillalai_South-J/149',
  'gn:Mathagal_East-J/150',
  'gn:Mathagal_South-J/151',
  'gn:Mathagal_West-J/152',
  'gn:Periyavilan-J/153',
  'gn:Marisankoodal-J/154',
  'gn:Illavalai-J/155',
  'gn:Mullanai-J/156',
  'gn:Mallakam_South-J/212',
  'gn:Mallakam_Centre-J/213',
  'gn:Mallakam_North-J/214',
  'gn:Alaveddy_North-J/215',
  'gn:Alaveddy_Centre-J/216',
  'gn:Alaveddy_East-J/217',
  'gn:Ganeswaram-J/218',
  'gn:Alaveddy_South-J/219',
  'gn:Alaveddy_West-J/220',
  'gn:Illavalai_North-J/221',
  'gn:Illavalai_North_West-J/222',
  'gn:Viththagapuram-J/223',
  'gn:Ponnalai-J/224',
  'gn:Kollankalddy-J/225',
  'gn:Naguleswaram-J/226',
  'gn:Tellipalai_East-J/227',
  'gn:Tellipalai-J/228',
  'gn:Thurkkapuram-J/229',
  'gn:Thanthai_Selvapuram-J/230',
  'gn:Maviddapuram-J/231',
  'gn:Maviddapuram_South-J/232',
  'gn:Kankesanthurai_West-J/233',
  'gn:Kankesanthural_Centre-J/234',
  'gn:Kankesanthural_South-J/235',
  'gn:Palaiveemankamam_North-J/236',
  'gn:Palaiveemankamam_South-J/237',
  'gn:Kadduvan-J/238',
  'gn:Kadduvan_West-J/239',
  'gn:Thenyamalai-J/240',
  'gn:Varuthlaivilan-J/241',
  'gn:Kurumpasiddy-J/242',
  'gn:Kurumpasiddy_East-J/243',
  'gn:Vasavilan-J/244',
  'gn:Vasavilan_West-J/245',
  'gn:Magiliddy_North-J/246',
  'gn:Thaiyiddy_East-J/247',
  'gn:Mayiliddythurai_South-J/248',
  'gn:Thaiyiddy_North-J/249',
  'gn:Thaiyiddy_South-J/250',
  'gn:Mayiliddythurai_North-J/251',
  'gn:Palaly_South-J/252',
  'gn:Palaly_East-J/253',
  'gn:Palaly_North-J/254',
  'gn:Palaly_North_West-J/255',
  'gn:Palaly_West-J/256',
  'gn:Uduvil_South_West-J/182',
  'gn:Uduvil_South_East-J/183',
  'gn:Uduvil_Centre-J/184',
  'gn:Uduvil_Centre_North-J/185',
  'gn:Uduvil_North-J/186',
  'gn:Sanguvely-J/187',
  'gn:Inuvil_South_West-J/188',
  'gn:Inuvil_East-J/189',
  'gn:Inuvil_North_East-J/190',
  'gn:Inuvil_West-J/191',
  'gn:Thavady_South-J/192',
  'gn:Thavady_East-J/193',
  'gn:Thavady_North-J/194',
  'gn:Chunnakam_Town_North-J/195',
  'gn:Chunnakam_Town_South-J/196',
  'gn:Chunnakam_Town_East-J/197',
  'gn:Chunnakam_Town_Centre-J/198',
  'gn:Chunnakam_Town_West-J/199',
  'gn:Kantharodai-J/200',
  'gn:Eralalai_West-J/201',
  'gn:Eralalas_South_West-J/202',
  'gn:Eralalai_South-J/203',
  'gn:Eralalai_East-J/204',
  'gn:Eralalai_North-J/205',
  'gn:Eralalai_Centre-J/206',
  'gn:Punnalaikadduvan_South-J/207',
  'gn:Punnalaikadduvan_North-J/208',
  'gn:Eevinai-J/209',
  'gn:Kuppilan_South-J/210',
  'gn:Kuppilan_North-J/211',
  'gn:Irupalai_South-J/257',
  'gn:Irupalai_East-J/258',
  'gn:Kalviyankadu-J/259',
  'gn:Kopay_South-J/260',
  'gn:Kopay_Centre-J/261',
  'gn:Kopay_North-J/262',
  'gn:Urumpirai_West-J/263',
  'gn:Urumpirai_North-J/264',
  'gn:Urumpirai_South-J/265',
  'gn:Urumpirai_East-J/266',
  'gn:Urelu-J/267',
  'gn:Neervely_South-J/268',
  'gn:Neervely_North-J/269',
  'gn:Neervely_West-J/270',
  'gn:Sirupiddy_East-J/271',
  'gn:Sirupiddy_West-J/272',
  'gn:Puttur_West-J/273',
  'gn:Puttur_North-J/274',
  'gn:Navakiri-J/275',
  'gn:Avarangal_East-J/276',
  'gn:Avarangal_West-J/277',
  'gn:Puttur_East-J/278',
  'gn:Achchehelu-J/279',
  'gn:Vatharawaththai-J/280',
  'gn:Pathaimeny-J/281',
  'gn:Thambalai_Kathiripay-J/282',
  'gn:Idaikadu-J/283',
  'gn:Valalai-J/284',
  'gn:Atchuvely_North-J/285',
  'gn:Atchavely_South-J/286',
  'gn:Atchuvely_West-J/287',
  'gn:Karanavai_South-J/348',
  'gn:Karanavai_West-J/349',
  'gn:Karanavai-J/350',
  'gn:Karanavai_East-J/351',
  'gn:Udupiddy-J/352',
  'gn:Udupiddy_North-J/353',
  'gn:Udupiddy_South-J/354',
  'gn:Valvetty-J/355',
  'gn:Velvetty_Centre-J/356',
  'gn:Samarapaku-J/357',
  'gn:Imayanan-J/358',
  'gn:Imayanan_West-J/359',
  'gn:Karanavai__North-J/360',
  'gn:Karanavai_North_West-J/361',
  'gn:Karanavai_Centre-J/362',
  'gn:Karaveddy_West-J/363',
  'gn:Karaveddy_North-J/364',
  'gn:Karaveddy_South-J/365',
  'gn:Maththony-J/366',
  'gn:Karaveddy_Centre-J/367',
  'gn:Karaveddy_East-J/368',
  'gn:Kaddaiveli-J/369',
  'gn:Thunnalai_South-J/370',
  'gn:Thunnalai_East-J/371',
  'gn:Thunnalai-J/372',
  'gn:Thunnalai_Centre-J/373',
  'gn:Thunnalai_West-J/374',
  'gn:Nelliady_North-J/375',
  'gn:Nelliady-J/376',
  'gn:Nellaidy_East-J/377',
  'gn:Alvai-J/378',
  'gn:Alvai_South-J/379',
  'gn:Alvai_East-J/380',
  'gn:Aththai-J/381',
  'gn:Kapputhoo-J/382',
  'gn:Manatkada-J/418',
  'gn:Kudaththanai-J/419',
  'gn:Kudaththanai_Karaiyoor-North-J/420',
  'gn:Potpathy-J/421',
  'gn:Ampan-J/422',
  'gn:Nagarkovil_East-J/423',
  'gn:Nagarkovil_West-J/424',
  'gn:Nagarkovil_South-J/425',
  'gn:Chempianpattu_North-J/426',
  'gn:Chempianpattu_South-J/427',
  'gn:Maruthankerny-J/428',
  'gn:Vaththirayan-J/429',
  'gn:Uduththurai-J/430',
  'gn:Aliyavalai-J/431',
  'gn:Vettilaikerny-J/432',
  'gn:Mullain-J/433',
  'gn:Pokkaruppu-J/434',
  'gn:Chundikulam-J/435',
  'gn:Thondamannaru_South-J/383',
  'gn:Thondamanmaru_North-J/384',
  'gn:Karudavil_South-J/385',
  'gn:Karudavil_North-J/386',
  'gn:Karudavil_East-J/387',
  'gn:Valvettithurai_North_West-J/388',
  'gn:Valvettithurai_North_Centre-J/389',
  'gn:Valvettithurai_North_East-J/390',
  'gn:Valvettithurai_South_West-J/391',
  'gn:Valvettithurai_South_East-J/392',
  'gn:Polikandy_West-J/393',
  'gn:Polikandy_East-J/394',
  'gn:Polikandy_South-J/395',
  'gn:Alvai_West-J/396',
  'gn:Alvai_North_West-J/397',
  'gn:Alvai_North_Centre-J/398',
  'gn:Viyaparimoolai-J/399',
  'gn:Alvai_North-J/400',
  'gn:Point_Pedro-J/401',
  'gn:Point_Pedro_South-J/402',
  'gn:Point_Pedro_East-J/403',
  'gn:Thumpalai-J/404',
  'gn:Thumpalai_East-J/405',
  'gn:Katkovalam-J/406',
  'gn:Puloly_North-J/407',
  'gn:Puloly_North_East-J/408',
  'gn:Puloly_Centre-J/409',
  'gn:Puloly_West-J/410',
  'gn:Puloly_South_West-J/411',
  'gn:Puloly_North_West-J/412',
  'gn:Puloly_East-J/413',
  'gn:Puloly_South-J/414',
  'gn:Manthaikai-J/415',
  'gn:Vallipuram-J/416',
  'gn:Thunnali_North-J/417',
  'gn:Kaithady_North-J/288',
  'gn:Kaithady_East-J/289',
  'gn:Kaithady_Centre-J/290',
  'gn:Kaithady_South-J/291',
  'gn:Kaithady_South_East-J/292',
  'gn:Kaithady_West-J/293',
  'gn:Navatkuly_West-J/294',
  'gn:Navatkuly_East-J/295',
  'gn:Kogilakandy-J/296',
  'gn:Kaithady_Navatkuly-J/297',
  'gn:Maravanpulo-J/298',
  'gn:Thanankillappu-J/299',
  'gn:Chavakachcheri_Town-J/300',
  'gn:Kovitkudyiruppu-J/301',
  'gn:Sankathanai-J/302',
  'gn:Chavakachcheri_North-J/303',
  'gn:Manduvil-J/304',
  'gn:Kalvayal-J/305',
  'gn:Nunavil_East-J/306',
  'gn:Nunavil_Centre-J/307',
  'gn:Nunavil_West-J/308',
  'gn:Kaithady_Nunavil-_J/309',
  'gn:Themattuvil-J/310',
  'gn:Maddavil_Nunavil-J/311',
  'gn:Madduvil_Centre-J/312',
  'gn:Madduvil_North-J/313',
  'gn:Madduvil_East-J/314',
  'gn:Chandrapuram-J/315',
  'gn:Sarasalai_South-J/316',
  'gn:Sarasalai_North-J/317',
  'gn:Meesalai_East-J/318',
  'gn:Meesalai_West-J/319',
  'gn:Ramavil-J/320',
  'gn:Meesalai_North-J/321',
  'gn:Allaral-J/322',
  'gn:Vellampokkaddy-J/323',
  'gn:Kachchai-J/324',
  'gn:Palavi-J/325',
  'gn:Kodikamam_North-J/326',
  'gn:Kodikamam_Centre-J/327',
  'gn:Kodikamam_South-J/328',
  'gn:Ushan-J/329',
  'gn:Karampaham-J/330',
  'gn:Vidaththalpallai-J/331',
  'gn:Ketpeli-J/332',
  'gn:Eluthumaadwarl_South-J/333',
  'gn:Eluthumaadwart_North-J/334',
  'gn:Mirusuvil_North-J/335',
  'gn:Mirusuvil_South-J/336',
  'gn:Kudamiyan-J/337',
  'gn:Navatkadu-J/338',
  'gn:Varany_North-J/339',
  'gn:Maseri-J/340',
  'gn:Idaikurichchi-J/341',
  'gn:Karampaikurichchi-J/342',
  'gn:Varani_Iyattalai-J/343',
  'gn:Thavalai_lyattalai-J/344',
  'gn:Manthuvil_East-J/345',
  'gn:Manthuvil_West-J/346',
  'gn:Manthuvil_North-J/347',
  'gn:Ariyalai_South_East-J/89',
  'gn:Aniyalai_East-J/90',
  'gn:Ariyalai_North_West-J/91',
  'gn:Ariyalai_Centre_West-J/92',
  'gn:Anyalai_South_West-J/93',
  'gn:Ariyalai_Centre_North-J/94',
  'gn:Ariyalai_Centre-J/95',
  'gn:Ariyalai_Centre_South-J/96',
  'gn:Iyanarkovillady-J/97',
  'gn:Vannarpannai_North-J/98',
  'gn:Vannarpannai_North_West-J/99',
  'gn:Vannarpannai_North_East-J/100',
  'gn:Neeraviyady-J/101',
  'gn:Kandarmadam_North_West-J/102',
  'gn:Kandarmadam_North_East-J/103',
  'gn:Kandarmadam_South_West-J/104',
  'gn:Kandarmadam_South_East-J/105',
  'gn:Nallur_North-J/106',
  'gn:Nallur_Rajathani-_J/107',
  'gn:Nallur-J/108',
  'gn:Sangilian_Thoppu-J/109',
  'gn:Thirunelvely_West-J/110',
  'gn:Thirunelvely_Centre_South-J/111',
  'gn:Thirunelvely_South_East-J/112',
  'gn:Thirunelvely_North_East-J/113',
  'gn:Thirunelvely_Centre_North-J/114',
  'gn:Kondavil_North_West-J/115',
  'gn:Kondasil_South_West-J/116',
  'gn:Koodavil_Centre_West-J/117',
  'gn:Kondavil_Centre_East-J/118',
  'gn:Koodavil_North_East-J/119',
  'gn:Kondavil_South_East-J/120',
  'gn:Kokkuvil_North_East-J/121',
  'gn:Kokkuvil_East-J/122',
  'gn:Kokkuvil_South_East-J/123',
  'gn:Kokkuvil_North_West-J/124',
  'gn:Kokkuvil_West-J/125',
  'gn:Kokkuvil_Centre_East-J/126',
  'gn:Kokkuvil_South_West-J/127',
  'gn:Kokkuvil_Centre_West-J/128',
  'gn:Nedunkulam-_J/61',
  'gn:Columbuthurai_East-J/62',
  'gn:Columbutharai_West-J/63',
  'gn:Passaiyoor_East-J/64',
  'gn:Passaiyoor_West-J/65',
  'gn:Eachchamoddai-_J/66',
  'gn:Thirumagar-J/67',
  'gn:Reclamation_East-J/68',
  'gn:Reclamation_West-J/69',
  'gn:Gurunagar_East-J/70',
  'gn:Gurunagar_West-J/71',
  'gn:Small_Bazzar-J/72',
  'gn:Jaffna_Town_West-J/73',
  'gn:Jaffna_Town_East-J/74',
  'gn:Chundakali_South-J/75',
  'gn:Chundakul_North-J/76',
  'gn:Maruthady-J/77',
  'gn:Aththiyady-J/78',
  'gn:Sirampiady-J/79',
  'gn:Grand_Bazzar-J/80',
  'gn:Fort-J/81',
  'gn:Vannarponnai-J/82',
  'gn:Koddady-J/83',
  'gn:Navanthurai_South-J/84',
  'gn:Navanthurai_North-J/85',
  'gn:Moor_Street_South-J/86',
  'gn:Moor_Street_North-J/87',
  'gn:New_Moor_Street-J/88',
  'gn:Mandaitivu_East-J/07',
  'gn:Mandaitivu_West-J/08',
  'gn:Mandaitivu_South-J/09',
  'gn:Allaipiddy-J/10',
  'gn:Mankumpan-J/11',
  'gn:Velanai_North-J/12',
  'gn:Velanai_North_East-J/13',
  'gn:Velanai_East-J/14',
  'gn:Velanai_South_East-J/15',
  'gn:Velana_East_Centre-J/16',
  'gn:Velanai_South-J/17',
  'gn:Velanai_West_Centre-J/18',
  'gn:Velanai_West-J/19',
  'gn:Saravanai_East-J/20',
  'gn:Saravanai_West-J/21',
  'gn:Pungudutivu_North_East-J/22',
  'gn:Pungudutivu_East-J/23',
  'gn:Pungudutivu_South_East-J/24',
  'gn:Pungudutivu_East_South-J/25',
  'gn:Pungudutive_South-J/26',
  'gn:Pungudative_North-J/27',
  'gn:Pungudutivu_Centre_North-J/28',
  'gn:Pungudutivu_South_West-J/29',
  'gn:Punguditivu_Centre_West-J/30',
  'gn:Punguditivu_Centre_East-J/31',
  'gn:Pungudutivu_North_West-J/32',
  'gn:Pungudutivu_West-J/33',
  'gn:Nainativu_North-J/34',
  'gn:Nainativu_Centre-J/35',
  'gn:Nainativu_South-J/36',
  'gn:Delft_West-J/01',
  'gn:Delft_South-J/02',
  'gn:Delft_Centre_West-J/03',
  'gn:Delft_Centre-J/04',
  'gn:Delft_Centre_East-J/05',
  'gn:Delft_East-J/06',
  'gn:Karainagar_West-J/40',
  'gn:Karainagar_North_West-J/41',
  'gn:Karainagar_East-J/42',
  'gn:Karainagar_South_East-J/43',
  'gn:Karainagar_South-J/44',
  'gn:Karainagar_South_West-J/45',
  'gn:Karainagar_North-J/46',
  'gn:Karainagar_North_East-J/47',
  'gn:Karainagar_Centre-J/48',
  'gn:Talaimannar_Village_North-MNR/48',
  'gn:Talaimannur_Village_South-MNR/49',
  'gn:Talaimannar_pier_West-MNR/50',
  'gn:Talaimannar_pier_East-MNR/51',
  'gn:Talaimannar-MNR/52',
  'gn:Kaddukarankudiyiruppu-MNR/53',
  'gn:Thullukudiyiruppu-MNR/54',
  'gn:Pesalai_west-MNR/55',
  'gn:Pesalai_South-MNR/56',
  'gn:Pesalai_North-MNR/57',
  'gn:Siruthoppu-MNR/58',
  'gn:Periya_kerisal-MNR/59',
  'gn:Olaithodduwai-MNR/60',
  'gn:Pthukudiyiruppu-MNR/61',
  'gn:Thoddawely-MNR/62',
  'gn:Erukkalampiddy_West-MNR/63',
  'gn:Erukkalampiddy_North-MNR/64',
  'gn:Erukkalampiddy_East-MNR/65',
  'gn:Erukkalampiddy_South-MNR/66',
  'gn:Erukkalampiddy_Central-MNR/67',
  'gn:Tharapuram_west-MNR/68',
  'gn:Tharapuram_East-MNR/69',
  'gn:Thalvpadu-MNR/70',
  'gn:Pattim-MNR/71',
  'gn:Eluthoor-MNR/72',
  'gn:South_bar-MNR/73',
  'gn:Emil_Nagar-MNR/74',
  'gn:Chavatkaddu-MNR/75',
  'gn:Panankaddikoddu_west-MNR/76',
  'gn:Panankaddikoddu_East-MNR/77',
  'gn:Sinnakadai-MNR/78',
  'gn:Pettah-MNR/79',
  'gn:Periyakadai-MNR/80',
  'gn:Moor_Street-MNR/81',
  'gn:Uppukulum_North-MNR/82',
  'gn:Uppukulam_South-MNR/83',
  'gn:Pallimunai_East-MNR/84',
  'gn:Pallimunai_West-MNR/85',
  'gn:Thiruketheeswaram-MNR/86',
  'gn:Periyanavatkulam-MNR/87',
  'gn:Nagathalvu-MNR/88',
  'gn:Neelasenai-MNR/89',
  'gn:Kallikaddaskadu-MNR/90',
  'gn:Puthakamam-MNR/91',
  'gn:Uyilankulam-MNR/92',
  'gn:Mathoddam-MNR/93',
  'gn:Vannamoddai-MNR/94',
  'gn:Uyirtharasankulam-MNR/95',
  'gn:Parappankandal-MNR/96',
  'gn:Vellankulam-MNR/01',
  'gn:Thevanpiddy-MNR/02',
  'gn:Pali_Aru-MNR/03',
  'gn:Illuppaikadavai-MNR/04',
  'gn:Anthoniyarpuram-MNR/05',
  'gn:Kalliyady-MNR/06',
  'gn:Kurai-MNR/07',
  'gn:Aththimoddai-MNR/08',
  'gn:Kovitkulam-MNR/09',
  'gn:Vidataltivu_West-MNR/10',
  'gn:Vidataltivu_North-MNR/11',
  'gn:Vidataltivu_Central-MNR/12',
  'gn:Vidataltivu_East-MNR/13',
  'gn:Pallamalhu-MNR/14',
  'gn:Kaya_Nagar-MNR/15',
  'gn:Periyamadhu_West-MNR/16',
  'gn:Periyamadhu_East-MNR/17',
  'gn:Pappamoddai-MNR/18',
  'gn:Veddayamurippu-MNR/19',
  'gn:Minukkan-MNR/20',
  'gn:Maligaithiddal-MNR/21',
  'gn:Adampan-MNR/22',
  'gn:Palaikuly-MNR/23',
  'gn:Nedunkandal-MNR/24',
  'gn:Sornapuri-MNR/25',
  'gn:Karunkandal-MNR/26',
  'gn:Andankulam-MNR/27',
  'gn:Kannady-MNR/28',
  'gn:Alkaddively-MNR/29',
  'gn:Kannkandal_Vannakulam-MNR/30',
  'gn:Kaththankalam-MNR/31',
  'gn:Vaddakandal-MNR/32',
  'gn:Palai_Periymalkaddu-MNR/33',
  'gn:Palaiyadi_Puthukulam-MNR/34',
  'gn:Parappukadanthan_west-MNR/35',
  'gn:Parappukadanthan_East-MNR/36',
  'gn:Madhu-MNR/37',
  'gn:Periya_Pandiviruchchan_West-MNR/38',
  'gn:Periya_Pandiviruchchan_East-MNR/39',
  'gn:Palampiddy-MNR/40',
  'gn:Keerisuddan-MNR/41',
  'gn:Iranai_Illuppaikulam-MNR/42',
  'gn:Vilaththikulam-MNR/43',
  'gn:Parasankalam-MNR/44',
  'gn:Kakkayankulam_West-MNR/45',
  'gn:Kakkayankulam_East-MNR/46',
  'gn:Kalmadhu-MNR/47',
  'gn:Maluvarayar_Kaddaiyadumpan-MNR/128',
  'gn:Pannavedduvan-MNR/129',
  'gn:Pomalarnthan-MNR/130',
  'gn:Thekkam-MNR/131',
  'gn:Matha_Gramam-MNR/132',
  'gn:Periyamurippu-MNR/133',
  'gn:Vankalai_North-MNR/97',
  'gn:Vankalai_west-MNR/98',
  'gn:Vankalai_east-MNR/99',
  'gn:Thomaspuri-MNR/100',
  'gn:Naruvilikalam-MNR/101',
  'gn:Vanchchiankulam-MNR/102',
  'gn:Umanagari-MNR/103',
  'gn:Achchankulam-MNR/104',
  'gn:Rasamadhu-MNR/105',
  'gn:Moddaikadal-MNR/106',
  'gn:Nanaddan-MNR/107',
  'gn:Pallankoddai-MNR/108',
  'gn:Valkaipadankandal-MNR/109',
  'gn:Periyakadlaikadu-MNR/110',
  'gn:Illahadipiddy-MNR/111',
  'gn:Illanthaimoddai-MNR/112',
  'gn:Puthirakanidan-MNR/113',
  'gn:Razool_puthuvely-MNR/114',
  'gn:Kanchchithalvu-MNR/115',
  'gn:Aththukuly-MNR/116',
  'gn:Chemmantivu-MNR/117',
  'gn:Murangan-MNR/118',
  'gn:Chundukuly-MNR/119',
  'gn:Sirukandal-MNR/120',
  'gn:Pontheevukandal-MNR/121',
  'gn:Kalimoddai_Paliyankulam-MNR/122',
  'gn:Paritharikandal-MNR/123',
  'gn:Katkidanthakulam-MNR/124',
  'gn:Irraddaikulam-MNR/125',
  'gn:Cheddiamagan_Kaddaiyadampan-MNR/126',
  'gn:Isamalaithalvu-MNR/127',
  'gn:Arippu_west-MNR/134',
  'gn:Arippu_east-MNR/135',
  'gn:Meththanvely-MNR/136',
  'gn:Pandaravely-MNR/137',
  'gn:Poonochchikulam-MNR/138',
  'gn:Maruthamadu-MNR/139',
  'gn:Veppunkulam-MNR/140',
  'gn:Periya_Pillachchi_Pokerny-MNR/141',
  'gn:Sinna_pullachchi_potkerny-MNR/142',
  'gn:Ahathymurippu-MNR/143',
  'gn:Chilavathurai-MNR/144',
  'gn:Saveriyarpuram-MNR/145',
  'gn:Puthuvely-MNR/146',
  'gn:Koolankulam-MNR/147',
  'gn:Kokkupadayan-MNR/148',
  'gn:Kondachchi-MNR/149',
  'gn:Karadikuly-MNR/150',
  'gn:Marichchukaddi-MNR/151',
  'gn:Palaikuly-MNR/152',
  'gn:Mallikulam-MNR/153',
  'gn:Unchalkaddy-221',
  'gn:Velivaithakallu-221A',
  'gn:Maruthodai-221B',
  'gn:Paddikudiyiruppu-221C',
  'gn:Katkulam-221D',
  'gn:Nedunkerny_South-222',
  'gn:Nedunkerny_North-222A',
  'gn:Olumadu-222B',
  'gn:Mamadu-222C',
  'gn:Kulavisuddan-222D',
  'gn:Maraillupai-222E',
  'gn:Paranthan-223',
  'gn:Ananthapuliyankalam-223A',
  'gn:Sinnaadampan-223B',
  'gn:Nainamadu-223C',
  'gn:Puliyankalam_South-224',
  'gn:Puliyankalam_North-224A',
  'gn:Kanagarayankulam_South-225',
  'gn:Kanagarayankulam_North-225A',
  'gn:Mannakulam-225B',
  'gn:Periya_Ulukulama-C209',
  'gn:Poomaduwa-C209A',
  'gn:Track-7-C209B',
  'gn:Marathanmadawa-C209C',
  'gn:Rangethgama-C209D',
  'gn:Avarantulawa-C209E',
  'gn:Acre_20,40,_60-C209F',
  'gn:Acre_400.L.B._Coloni-C209G',
  'gn:Madukanda-C212',
  'gn:Erattaperiyakulam-C212A',
  'gn:Alagalla-C212B',
  'gn:Avusadapitiya-C212C',
  'gn:Kalukkunamaduwa-C212D',
  'gn:Nedunkalama-C212E',
  'gn:Mahamylankulam-C212F',
  'gn:Mamaduwa-C213',
  'gn:Mahakachakodiya-C213A',
  'gn:Puthubulankalama-C213B',
  'gn:Pirappanmaduwa-C213C',
  'gn:Agbopura-C213D',
  'gn:Vavuniya_Town-214',
  'gn:Thandikulam-214A',
  'gn:Paddanichchipuliyankulam-214B',
  'gn:Vavuniya_North-214C',
  'gn:Vairavapuliyan_kalam-214D',
  'gn:Pandarikulam-214E',
  'gn:Thonikal-214F',
  'gn:Moonrumurippu-214G',
  'gn:Nochchimoddai-215',
  'gn:Maharamhaikulam-215A',
  'gn:Katharsinnakulam-215B',
  'gn:Kallikulam-215C',
  'gn:Puthkulam-216',
  'gn:Paranaddankal-216A',
  'gn:Rajendrankulam-217',
  'gn:Poovarasankulam-217A',
  'gn:Salampaikulam-217B',
  'gn:Chekkaddipulavu-217C',
  'gn:Velankulam-217D',
  'gn:Nelukulam-218',
  'gn:Pampaimada-218A',
  'gn:Kalmadu-218B',
  'gn:Marakkaranpalai-218C',
  'gn:Sasthirikoolankulam-218D',
  'gn:Eachchankulam-218E',
  'gn:Koomankulam-218F',
  'gn:Kanthapuram-218G',
  'gn:Maruthamadu-219',
  'gn:Palamoddai-219A',
  'gn:Maruthankulam-220',
  'gn:Semamadu-220A',
  'gn:Malligai-220B',
  'gn:Pantrikeythakulam-220C',
  'gn:Omanthai-220D',
  'gn:Arumugathanouthukulam-220E',
  'gn:Mahilankulam-220F',
  'gn:Elamaruthankulam-220G',
  'gn:Asikulam-244',
  'gn:Rambaikulam-244A',
  'gn:Samalankulam-244B',
  'gn:Kovilkulam-244C',
  'gn:Velikulam-244D',
  'gn:Andiyapuliyankulam-207',
  'gn:Periyathampanai-207A',
  'gn:Periyakadu-207B',
  'gn:Kannady-207C',
  'gn:Piramanalankulam-207D',
  'gn:Sooduventhapulava-208',
  'gn:Kurukkalputhukulam-208A',
  'gn:Kamhavamy_Nagar-208B',
  'gn:Pavatkulam_unit_5_&_6-208C',
  'gn:Pavatkulam_Unit_4-208D',
  'gn:Pavatkulam_unit_2-208E',
  'gn:Muthaliyakalam-210',
  'gn:Sinnasippikalum-210A',
  'gn:Neriyakulam-210B',
  'gn:Periyapuliyankulam-211',
  'gn:Cheddikulam-211A',
  'gn:Muhathankulam-211B',
  'gn:Pavatkulam_Unit_9_&_10-211C',
  'gn:Christthokulam-211D',
  'gn:Kankankulam-211E',
  'gn:Thunukkai-MUL/16',
  'gn:Kalvilan-MUL/17',
  'gn:Uyilakulam-MUL/18',
  'gn:Yogapurum_West-MUL/19',
  'gn:Mallavi-MUL/20',
  'gn:Yogapuram_Centre-MUL/21',
  'gn:Thirunagar-MUL/22',
  'gn:Puthuvedduvan-MUL/23',
  'gn:Amaithipuram-MUL/24',
  'gn:Amplapperumalkulam-MUL/25',
  'gn:Alankulam-MUL/26',
  'gn:Therkandal-MUL/27',
  'gn:Yogapurum_East-MUL/28',
  'gn:Pugalenthynagar-MUL/29',
  'gn:Barathinagar-MUL/30',
  'gn:Anichchiyankulam-MUL/31',
  'gn:Thenniyankulam-MUL/32',
  'gn:Palayamarkandy-MUL/33',
  'gn:Iyankulam-MUL/34',
  'gn:Koddaikaddiyakulam-MUL/35',
  'gn:Vannivilankulam-MUL/1',
  'gn:Ampalpuram-MUL/2',
  'gn:Kollavilankulam-MUL/3',
  'gn:Oddaruthakulam-MUL/4',
  'gn:Sivapuram-MUL/5',
  'gn:Palinagar-MUL/6',
  'gn:Karumpulliyan-MUL/7',
  'gn:Poovarasankulam-MUL/8',
  'gn:Pandiyankulam-MUL/9',
  'gn:Selvapuram-MUL/10',
  'gn:Moonrumurippu-MUL/11',
  'gn:Naddunkandal-MUL/12',
  'gn:Vinayagapuram-MUL/13',
  'gn:Ponnagar-MUL/14',
  'gn:Siraddikulam-MUL/15',
  'gn:Puthukkudiyiruppu_East-MUL/36',
  'gn:Sivanagar-MUL/37',
  'gn:Manthuvil-MUL/38',
  'gn:Malligaitheevu-MUL/39',
  'gn:Ananthapuram-MUL/40',
  'gn:Puthukkudiyiruppu_West-MUL/41',
  'gn:Kombavil-MUL/42',
  'gn:Iranaipalai-MUL/43',
  'gn:Thevipuram-MUL/44',
  'gn:Vallipuram-MUL/45',
  'gn:Udayarkaddu_North-MUL/46',
  'gn:Udayarkaddu_South-MUL/47',
  'gn:Suthanthirapuram-MUL/48',
  'gn:Visvamadu_west-MUL/49',
  'gn:Visvamadu_East-MUL/50',
  'gn:Manikkaporam-MUL/51',
  'gn:Theravil-MUL/52',
  'gn:Vallavarpuram-MUL/53',
  'gn:Manakandal-MUL/54',
  'gn:Muththayankaddu_Kulam-MUL/55',
  'gn:Kanagaratnapuram-MUL/56',
  'gn:Peraru-MUL/57',
  'gn:Pandaravanni-MUL/58',
  'gn:Katchilaimadu-MUL/59',
  'gn:Muththuvinayagapuram-MUL/60',
  'gn:Oddusuddan-MUL/61',
  'gn:Kathaliyar_Sumalankulum-MUL/62',
  'gn:Thaddayamalai-MUL/63',
  'gn:Ganeshapuram-MUL/64',
  'gn:Vithiyapuram-MUL/65',
  'gn:Karavelankandal-MUL/66',
  'gn:Puliyankulam-MUL/67',
  'gn:Koolamurippu-MUL/68',
  'gn:Manavalanddamppu-MUL/69',
  'gn:Thachchadampan-MUL/70',
  'gn:Olumadu-MUL/71',
  'gn:Ampugaman-MUL/72',
  'gn:Mankulam-MUL/73',
  'gn:Panikkankulam-MUL/74',
  'gn:Thirumurukandy-MUL/75',
  'gn:Inthupuram-MUL/76',
  'gn:Oththimalai-MUL/123',
  'gn:Periyakalam-MUL/124',
  'gn:Thanduwan-MUL/125',
  'gn:Periyalthimadu-MUL/126',
  'gn:Palampasi-MUL/127',
  'gn:Kokulai_west-MUL/77',
  'gn:Kokulai_East-MUL/78',
  'gn:Karunaddukerny-MUL/79',
  'gn:Kokkuthoduvai_south-MUL/80',
  'gn:Kokkuthoduvai_North-MUL/81',
  'gn:Kokkuthoduvai_Center-MUL/82',
  'gn:Semmalai_East-MUL/83',
  'gn:Semmalai-MUL/84',
  'gn:Uppumavelli-MUL/85',
  'gn:Alambil_North-MUL/86',
  'gn:Alampil_south-MUL/87',
  'gn:Silawaththai-MUL/88',
  'gn:Silawaththai_south-MUL/89',
  'gn:Kallappau_south-MUL/90',
  'gn:Kallappau_north-MUL/91',
  'gn:Vannankulam-MUL/92',
  'gn:Mullaitivu_south-MUL/93',
  'gn:Selvapuram-MUL/94',
  'gn:Kovil_kudiyiruppu-MUL/95',
  'gn:Manalkudiyiruppu-MUL/96',
  'gn:Mullaitivu_town-MUL/97',
  'gn:Mullivaikkal_East-MUL/98',
  'gn:Mullivaikkal_West-MUL/99',
  'gn:Ambalavanan_Pokkanai-MUL/100',
  'gn:Vattapalai-MUL/101',
  'gn:Keppapilava-MUL/102',
  'gn:Mulliyawalai_west-MUL/103',
  'gn:Mulliyawalai_North-MUL/104',
  'gn:Putharikadu-MUL/105',
  'gn:Mulliyawalai_Centre-MUL/106',
  'gn:Mulliyavalai_South-MUL/107',
  'gn:Mulliyavalai_East-MUL/108',
  'gn:Thanniryoottu_West-MUL109/',
  'gn:Mamoolai-MUL/110',
  'gn:Mathavalasingankulam-MUL/111',
  'gn:Thanniyoothu_East-MUL/112',
  'gn:Neeravipiddi_East-MUL/113',
  'gn:Neeravipiddi_West-MUL/114',
  'gn:Gigrapuram-MUL/115',
  'gn:Kanukerny_East-MUL/116',
  'gn:Kanukerny_West-MUL/117',
  'gn:Kumarapuram-MUL/118',
  'gn:Kumulamunai_west-MUL/119',
  'gn:Thannimurippu-MUL/120',
  'gn:Kumulamunai_east-MUL/121',
  'gn:Kumulamunai_Centre-MUL/122',
  'gn:Nawa_Gajabapura-Weli/01',
  'gn:Kalyanapura-Weli/02',
  'gn:Ehetugaswewa-Weli/03',
  'gn:Ethawetubewews-Weli/04',
  'gn:Janakapura-Weli/05',
  'gn:Kinbbanwewa_Left-Weli/06',
  'gn:Nikawewa_South-Weli/07',
  'gn:Kinbbanwewa_Left-Weli/08',
  'gn:Gajabapura-Weli/09',
  'gn:Kovivayal-KN/78',
  'gn:Iyakkachchi-KN/79',
  'gn:Mugavil-KN/80',
  'gn:Masar-KN/81',
  'gn:Soranpattu-KN/82',
  'gn:Tharmakerny-KN/83',
  'gn:Pulopallai_East-KN/84',
  'gn:Mullaiyady-KN/85',
  'gn:Thampakamam-KN/86',
  'gn:Pallai_town-KN/87',
  'gn:Pulopallai_west-KN/88',
  'gn:Allipalai-KN/89',
  'gn:Kachcharvely-KN/90',
  'gn:Arasarkerny-KN/91',
  'gn:Ittavil-KN/92',
  'gn:Mugamalai-KN/93',
  'gn:Vemhodukemy-KN/94',
  'gn:Kilaly-KN/95',
  'gn:Kumarapuram-KN/43',
  'gn:Paranthan-KN/44',
  'gn:Umayalpuram-KN/45',
  'gn:Elephantpass-KN/46',
  'gn:Thadduvankoddy-KN/47',
  'gn:Korakkankadhu-KN/48',
  'gn:Uriyan-KN/49',
  'gn:Murasumoddai-KN/50',
  'gn:Kandawalai-KN/51',
  'gn:Periyakulam-KN/52',
  'gn:Kalmadunagar-KN/53',
  'gn:Tharmapuram_West-KN/54',
  'gn:Tharmapuram_East-KN/55',
  'gn:Puliyampokkanai-KN/56',
  'gn:Punnaineeravi-KN/57',
  'gn:Piramanthanaru-KN/58',
  'gn:Vannerikulam-KN/01',
  'gn:Anaiviluthankulam-KN/02',
  'gn:Kannakaipuram-KN/03',
  'gn:Skandapurum-KN/04',
  'gn:Akkarayankulam-KN/05',
  'gn:Konavil-KN/06',
  'gn:Ponnagar-KN/07',
  'gn:Bharathipuram-KN/08',
  'gn:Malayalapuram-KN/09',
  'gn:Vivegananthungar-KN/10',
  'gn:Krishnapuram-KN/11',
  'gn:Uthayanagar_East-KN/12',
  'gn:Uthayanagar_West-KN/13',
  'gn:Ambalkulam-KN/14',
  'gn:Selvanagar-KN/15',
  'gn:Ananthapuram-KN/16',
  'gn:Thondamannagar-KN/17',
  'gn:Kanagambigaikulam-KN/18',
  'gn:Ambalnagar-KN/19',
  'gn:Thiruvaiaru-KN/20',
  'gn:Thiruvaiaru_West-KN/21',
  'gn:Ratnapuram-KN/22',
  'gn:Kilinochchi_town-KN/23',
  'gn:Maruthanagar-KN/24',
  'gn:Pannankandy-KN/25',
  'gn:Kanagapurum-KN/26',
  'gn:Thirunagar_South-KN/27',
  'gn:Thirunagar_North-KN/28',
  'gn:Kaneshapuram-KN/29',
  'gn:Jeyanthinagar-KN/30',
  'gn:Periyaparanthan-KN/31',
  'gn:Uruthirapuram_North-KN/32',
  'gn:Uruthirapuram_East-KN/33',
  'gn:Uruthirapuram_West-KN/34',
  'gn:Sivanagar-KN/35',
  'gn:Uttupalam-KN/36',
  'gn:Puthumurippu-KN/37',
  'gn:Vadakachchi-KN/38',
  'gn:Civic_Centre-KN/39',
  'gn:Mayavanor-KN/40',
  'gn:Ramanathapuram-KN/41',
  'gn:Mavadiyamman-KN/42',
  'gn:Nallur-KN/59',
  'gn:Alankerny-KN/60',
  'gn:Kollakurichchi-KN/61',
  'gn:Cheddiyakurichchi-KN/62',
  'gn:Gnanimadam-KN/63',
  'gn:Madduvilnadu_East-KN/64',
  'gn:Pallikkudah-KN/65',
  'gn:Maddavilnadhu_West-KN/66',
  'gn:Paramankirai-KN/67',
  'gn:Gowtharimunai-KN/68',
  'gn:Jeyapuram_North-KN/69',
  'gn:Jeyapuram_South-KN/70',
  'gn:Kariyalainagapaduvan-KN/71',
  'gn:Pallavarayankadhu-KN/72',
  'gn:Mulankavil-KN/73',
  'gn:Nachchikaduh-KN/74',
  'gn:Kiranchi-KN/75',
  'gn:Ponnaveli-KN/76',
  'gn:Iranaithevu-KN/77',
];


export const groups = {
  "district": [
    {
      "value": "district:Jaffna",
      "name": "Jaffna"
    },
    {
      "value": "district:Mannar",
      "name": "Mannar"
    },
    {
      "value": "district:Vavuniya",
      "name": "Vavuniya"
    },
    {
      "value": "district:Mullaitivu",
      "name": "Mullaitivu"
    },
    {
      "value": "district:Kilinochchi",
      "name": "Kilinochchi"
    }
  ],
  "ds": [
    {
      "value": "ds:Island_North-Kayts",
      "name": "Island North-Kayts",
      "district": "Jaffna"
    },
    {
      "value": "ds:Valikamam_West-Chankanai",
      "name": "Valikamam West-Chankanai",
      "district": "Jaffna"
    },
    {
      "value": "ds:Valikamam_South-West-Sandilipay",
      "name": "Valikamam South-West-Sandilipay",
      "district": "Jaffna"
    },
    {
      "value": "ds:Valikamam_North-Tellipallai",
      "name": "Valikamam North-Tellipallai",
      "district": "Jaffna"
    },
    {
      "value": "ds:Valikamam_South-Uduvil",
      "name": "Valikamam South-Uduvil",
      "district": "Jaffna"
    },
    {
      "value": "ds:Valikamam-East-_Kopayi",
      "name": "Valikamam-East- Kopayi",
      "district": "Jaffna"
    },
    {
      "value": "ds:Vadamaradchy_South-West,_Karaveddy",
      "name": "Vadamaradchy South-West, Karaveddy",
      "district": "Jaffna"
    },
    {
      "value": "ds:Vadamaradchi_East-Maruthnkerny",
      "name": "Vadamaradchi East-Maruthnkerny",
      "district": "Jaffna"
    },
    {
      "value": "ds:Vadamaradchi_North-Point_Pedro",
      "name": "Vadamaradchi North-Point Pedro",
      "district": "Jaffna"
    },
    {
      "value": "ds:Thenmaradchi-Chavakachcheri",
      "name": "Thenmaradchi-Chavakachcheri",
      "district": "Jaffna"
    },
    {
      "value": "ds:Nallur",
      "name": "Nallur",
      "district": "Jaffna"
    },
    {
      "value": "ds:Jaffna",
      "name": "Jaffna",
      "district": "Jaffna"
    },
    {
      "value": "ds:Island_South_,Velanai",
      "name": "Island South ,Velanai",
      "district": "Jaffna"
    },
    {
      "value": "ds:Delft",
      "name": "Delft",
      "district": "Jaffna"
    },
    {
      "value": "ds:Karainagar",
      "name": "Karainagar",
      "district": "Jaffna"
    },
    {
      "value": "ds:Mannar_Town",
      "name": "Mannar Town",
      "district": "Mannar"
    },
    {
      "value": "ds:Manthai_West",
      "name": "Manthai West",
      "district": "Mannar"
    },
    {
      "value": "ds:Madhu",
      "name": "Madhu",
      "district": "Mannar"
    },
    {
      "value": "ds:Nanaddan",
      "name": "Nanaddan",
      "district": "Mannar"
    },
    {
      "value": "ds:Musalai",
      "name": "Musalai",
      "district": "Mannar"
    },
    {
      "value": "ds:Vavuniya_North",
      "name": "Vavuniya North",
      "district": "Vavuniya"
    },
    {
      "value": "ds:Vavuniya_South",
      "name": "Vavuniya South",
      "district": "Vavuniya"
    },
    {
      "value": "ds:Vavuniya",
      "name": "Vavuniya",
      "district": "Vavuniya"
    },
    {
      "value": "ds:Vengalacheddikulam",
      "name": "Vengalacheddikulam",
      "district": "Vavuniya"
    },
    {
      "value": "ds:Thunukkai",
      "name": "Thunukkai",
      "district": "Mullaitivu"
    },
    {
      "value": "ds:Manthai_East",
      "name": "Manthai East",
      "district": "Mullaitivu"
    },
    {
      "value": "ds:Puthukudiyiruppu",
      "name": "Puthukudiyiruppu",
      "district": "Mullaitivu"
    },
    {
      "value": "ds:Oddusuddan",
      "name": "Oddusuddan",
      "district": "Mullaitivu"
    },
    {
      "value": "ds:Maritimepattu",
      "name": "Maritimepattu",
      "district": "Mullaitivu"
    },
    {
      "value": "ds:Welioya",
      "name": "Welioya",
      "district": "Mullaitivu"
    },
    {
      "value": "ds:Pachchilaipalli",
      "name": "Pachchilaipalli",
      "district": "Kilinochchi"
    },
    {
      "value": "ds:Kandavalai",
      "name": "Kandavalai",
      "district": "Kilinochchi"
    },
    {
      "value": "ds:Karachchi",
      "name": "Karachchi",
      "district": "Kilinochchi"
    },
    {
      "value": "ds:Poonakary",
      "name": "Poonakary",
      "district": "Kilinochchi"
    }
  ],
  "gn": [
    {
      "value": "gn:Analaitivu_North-J/37",
      "name": "Analaitivu North-J/37",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Analaitivu_South-J/38",
      "name": "Analaitivu South-J/38",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Eluvaitivu-J/39",
      "name": "Eluvaitivu-J/39",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Kayts-J/49",
      "name": "Kayts-J/49",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Paruthiyadaippu-J/50",
      "name": "Paruthiyadaippu-J/50",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Karampon-J/51",
      "name": "Karampon-J/51",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Karampon_East-J/52",
      "name": "Karampon East-J/52",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Karampon_South_East-J/53",
      "name": "Karampon South East-J/53",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Karampon_West-J/54",
      "name": "Karampon West-J/54",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Naranthanai_North-J/55",
      "name": "Naranthanai North-J/55",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Naranthanai_North_West-J/56",
      "name": "Naranthanai North West-J/56",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Naranthanai-J/57",
      "name": "Naranthanai-J/57",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Naranthanai_North_West-J/58",
      "name": "Naranthanai North West-J/58",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Seruvil-J/59",
      "name": "Seruvil-J/59",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Puliyankoodal-J/60",
      "name": "Puliyankoodal-J/60",
      "ds": "Island North-Kayts"
    },
    {
      "value": "gn:Vaddukoddai_East-J/157",
      "name": "Vaddukoddai East-J/157",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Vaddukoddai_North-J/158",
      "name": "Vaddukoddai North-J/158",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Sangaraththai-J/159",
      "name": "Sangaraththai-J/159",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Araly_West-J/160",
      "name": "Araly West-J/160",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Araly_Centre-J/161",
      "name": "Araly Centre-J/161",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Araly_South-J/162",
      "name": "Araly South-J/162",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Araly_East-J/163",
      "name": "Araly East-J/163",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Araly_North-J/164",
      "name": "Araly North-J/164",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Vaddu_South-J/165",
      "name": "Vaddu South-J/165",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Vaddukoddai_South_West-J/166",
      "name": "Vaddukoddai South West-J/166",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Vaddukoddai_West-J/167",
      "name": "Vaddukoddai West-J/167",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Tholpuram_East-J/168",
      "name": "Tholpuram East-J/168",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Tholpuram_West-J/169",
      "name": "Tholpuram West-J/169",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Ponnalai-J/170",
      "name": "Ponnalai-J/170",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Moolai-J/171",
      "name": "Moolai-J/171",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Chulipuram_West-J/172",
      "name": "Chulipuram West-J/172",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Chulipuram_Centre-J/173",
      "name": "Chulipuram Centre-J/173",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Chulipuram_East-J/174",
      "name": "Chulipuram East-J/174",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Pannakam-J/175",
      "name": "Pannakam-J/175",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Panipulam-J/176",
      "name": "Panipulam-J/176",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Sithankerny-J/177",
      "name": "Sithankerny-J/177",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Chankanai_East-J/178",
      "name": "Chankanai East-J/178",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Chankanai_West-J/179",
      "name": "Chankanai West-J/179",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Chankanai_South-J/180",
      "name": "Chankanai South-J/180",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Chankanai_Centre-J/181",
      "name": "Chankanai Centre-J/181",
      "ds": "Valikamam West-Chankanai"
    },
    {
      "value": "gn:Suthumalai_North-J/129",
      "name": "Suthumalai North-J/129",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Suthumalai_South-J/130",
      "name": "Suthumalai South-J/130",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Savatkaddu-J/131",
      "name": "Savatkaddu-J/131",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Uyarapulam-J/132",
      "name": "Uyarapulam-J/132",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Anaikoddadai-J/133",
      "name": "Anaikoddadai-J/133",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Navali_North-J/134",
      "name": "Navali North-J/134",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Navali_East-J/135",
      "name": "Navali East-J/135",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Navali_South-J/136",
      "name": "Navali South-J/136",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Manipay_North-J/137",
      "name": "Manipay North-J/137",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Manipay_East-J/138",
      "name": "Manipay East-J/138",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Manipay_South-J/139",
      "name": "Manipay South-J/139",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Manipay_West-J/140",
      "name": "Manipay West-J/140",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Sandilipay_North-J/141",
      "name": "Sandilipay North-J/141",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Sandilipay_Centre-J/142",
      "name": "Sandilipay Centre-J/142",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Sandilipay_West-J/143",
      "name": "Sandilipay West-J/143",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Mahiyapiddy-J/144",
      "name": "Mahiyapiddy-J/144",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Vadaliyadaippu-J/145",
      "name": "Vadaliyadaippu-J/145",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Pandaitharippu-J/146",
      "name": "Pandaitharippu-J/146",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Piranpattu-J/147",
      "name": "Piranpattu-J/147",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Chillalai_North-J/148",
      "name": "Chillalai North-J/148",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Chillalai_South-J/149",
      "name": "Chillalai South-J/149",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Mathagal_East-J/150",
      "name": "Mathagal East-J/150",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Mathagal_South-J/151",
      "name": "Mathagal South-J/151",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Mathagal_West-J/152",
      "name": "Mathagal West-J/152",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Periyavilan-J/153",
      "name": "Periyavilan-J/153",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Marisankoodal-J/154",
      "name": "Marisankoodal-J/154",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Illavalai-J/155",
      "name": "Illavalai-J/155",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Mullanai-J/156",
      "name": "Mullanai-J/156",
      "ds": "Valikamam South-West-Sandilipay"
    },
    {
      "value": "gn:Mallakam_South-J/212",
      "name": "Mallakam South-J/212",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Mallakam_Centre-J/213",
      "name": "Mallakam Centre-J/213",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Mallakam_North-J/214",
      "name": "Mallakam North-J/214",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Alaveddy_North-J/215",
      "name": "Alaveddy North-J/215",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Alaveddy_Centre-J/216",
      "name": "Alaveddy Centre-J/216",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Alaveddy_East-J/217",
      "name": "Alaveddy East-J/217",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Ganeswaram-J/218",
      "name": "Ganeswaram-J/218",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Alaveddy_South-J/219",
      "name": "Alaveddy South-J/219",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Alaveddy_West-J/220",
      "name": "Alaveddy West-J/220",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Illavalai_North-J/221",
      "name": "Illavalai North-J/221",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Illavalai_North_West-J/222",
      "name": "Illavalai North West-J/222",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Viththagapuram-J/223",
      "name": "Viththagapuram-J/223",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Ponnalai-J/224",
      "name": "Ponnalai-J/224",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Kollankalddy-J/225",
      "name": "Kollankalddy-J/225",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Naguleswaram-J/226",
      "name": "Naguleswaram-J/226",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Tellipalai_East-J/227",
      "name": "Tellipalai East-J/227",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Tellipalai-J/228",
      "name": "Tellipalai-J/228",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Thurkkapuram-J/229",
      "name": "Thurkkapuram-J/229",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Thanthai_Selvapuram-J/230",
      "name": "Thanthai Selvapuram-J/230",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Maviddapuram-J/231",
      "name": "Maviddapuram-J/231",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Maviddapuram_South-J/232",
      "name": "Maviddapuram South-J/232",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Kankesanthurai_West-J/233",
      "name": "Kankesanthurai West-J/233",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Kankesanthural_Centre-J/234",
      "name": "Kankesanthural Centre-J/234",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Kankesanthural_South-J/235",
      "name": "Kankesanthural South-J/235",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Palaiveemankamam_North-J/236",
      "name": "Palaiveemankamam North-J/236",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Palaiveemankamam_South-J/237",
      "name": "Palaiveemankamam South-J/237",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Kadduvan-J/238",
      "name": "Kadduvan-J/238",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Kadduvan_West-J/239",
      "name": "Kadduvan West-J/239",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Thenyamalai-J/240",
      "name": "Thenyamalai-J/240",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Varuthlaivilan-J/241",
      "name": "Varuthlaivilan-J/241",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Kurumpasiddy-J/242",
      "name": "Kurumpasiddy-J/242",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Kurumpasiddy_East-J/243",
      "name": "Kurumpasiddy East-J/243",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Vasavilan-J/244",
      "name": "Vasavilan-J/244",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Vasavilan_West-J/245",
      "name": "Vasavilan West-J/245",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Magiliddy_North-J/246",
      "name": "Magiliddy North-J/246",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Thaiyiddy_East-J/247",
      "name": "Thaiyiddy East-J/247",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Mayiliddythurai_South-J/248",
      "name": "Mayiliddythurai South-J/248",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Thaiyiddy_North-J/249",
      "name": "Thaiyiddy North-J/249",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Thaiyiddy_South-J/250",
      "name": "Thaiyiddy South-J/250",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Mayiliddythurai_North-J/251",
      "name": "Mayiliddythurai North-J/251",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Palaly_South-J/252",
      "name": "Palaly South-J/252",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Palaly_East-J/253",
      "name": "Palaly East-J/253",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Palaly_North-J/254",
      "name": "Palaly North-J/254",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Palaly_North_West-J/255",
      "name": "Palaly North West-J/255",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Palaly_West-J/256",
      "name": "Palaly West-J/256",
      "ds": "Valikamam North-Tellipallai"
    },
    {
      "value": "gn:Uduvil_South_West-J/182",
      "name": "Uduvil South West-J/182",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Uduvil_South_East-J/183",
      "name": "Uduvil South East-J/183",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Uduvil_Centre-J/184",
      "name": "Uduvil Centre-J/184",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Uduvil_Centre_North-J/185",
      "name": "Uduvil Centre North-J/185",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Uduvil_North-J/186",
      "name": "Uduvil North-J/186",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Sanguvely-J/187",
      "name": "Sanguvely-J/187",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Inuvil_South_West-J/188",
      "name": "Inuvil South West-J/188",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Inuvil_East-J/189",
      "name": "Inuvil East-J/189",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Inuvil_North_East-J/190",
      "name": "Inuvil North East-J/190",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Inuvil_West-J/191",
      "name": "Inuvil West-J/191",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Thavady_South-J/192",
      "name": "Thavady South-J/192",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Thavady_East-J/193",
      "name": "Thavady East-J/193",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Thavady_North-J/194",
      "name": "Thavady North-J/194",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Chunnakam_Town_North-J/195",
      "name": "Chunnakam Town North-J/195",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Chunnakam_Town_South-J/196",
      "name": "Chunnakam Town South-J/196",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Chunnakam_Town_East-J/197",
      "name": "Chunnakam Town East-J/197",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Chunnakam_Town_Centre-J/198",
      "name": "Chunnakam Town Centre-J/198",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Chunnakam_Town_West-J/199",
      "name": "Chunnakam Town West-J/199",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Kantharodai-J/200",
      "name": "Kantharodai-J/200",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Eralalai_West-J/201",
      "name": "Eralalai West-J/201",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Eralalas_South_West-J/202",
      "name": "Eralalas South West-J/202",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Eralalai_South-J/203",
      "name": "Eralalai South-J/203",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Eralalai_East-J/204",
      "name": "Eralalai East-J/204",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Eralalai_North-J/205",
      "name": "Eralalai North-J/205",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Eralalai_Centre-J/206",
      "name": "Eralalai Centre-J/206",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Punnalaikadduvan_South-J/207",
      "name": "Punnalaikadduvan South-J/207",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Punnalaikadduvan_North-J/208",
      "name": "Punnalaikadduvan North-J/208",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Eevinai-J/209",
      "name": "Eevinai-J/209",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Kuppilan_South-J/210",
      "name": "Kuppilan South-J/210",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Kuppilan_North-J/211",
      "name": "Kuppilan North-J/211",
      "ds": "Valikamam South-Uduvil"
    },
    {
      "value": "gn:Irupalai_South-J/257",
      "name": "Irupalai South-J/257",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Irupalai_East-J/258",
      "name": "Irupalai East-J/258",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Kalviyankadu-J/259",
      "name": "Kalviyankadu-J/259",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Kopay_South-J/260",
      "name": "Kopay South-J/260",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Kopay_Centre-J/261",
      "name": "Kopay Centre-J/261",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Kopay_North-J/262",
      "name": "Kopay North-J/262",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Urumpirai_West-J/263",
      "name": "Urumpirai West-J/263",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Urumpirai_North-J/264",
      "name": "Urumpirai North-J/264",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Urumpirai_South-J/265",
      "name": "Urumpirai South-J/265",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Urumpirai_East-J/266",
      "name": "Urumpirai East-J/266",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Urelu-J/267",
      "name": "Urelu-J/267",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Neervely_South-J/268",
      "name": "Neervely South-J/268",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Neervely_North-J/269",
      "name": "Neervely North-J/269",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Neervely_West-J/270",
      "name": "Neervely West-J/270",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Sirupiddy_East-J/271",
      "name": "Sirupiddy East-J/271",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Sirupiddy_West-J/272",
      "name": "Sirupiddy West-J/272",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Puttur_West-J/273",
      "name": "Puttur West-J/273",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Puttur_North-J/274",
      "name": "Puttur North-J/274",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Navakiri-J/275",
      "name": "Navakiri-J/275",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Avarangal_East-J/276",
      "name": "Avarangal East-J/276",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Avarangal_West-J/277",
      "name": "Avarangal West-J/277",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Puttur_East-J/278",
      "name": "Puttur East-J/278",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Achchehelu-J/279",
      "name": "Achchehelu-J/279",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Vatharawaththai-J/280",
      "name": "Vatharawaththai-J/280",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Pathaimeny-J/281",
      "name": "Pathaimeny-J/281",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Thambalai_Kathiripay-J/282",
      "name": "Thambalai Kathiripay-J/282",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Idaikadu-J/283",
      "name": "Idaikadu-J/283",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Valalai-J/284",
      "name": "Valalai-J/284",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Atchuvely_North-J/285",
      "name": "Atchuvely North-J/285",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Atchavely_South-J/286",
      "name": "Atchavely South-J/286",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Atchuvely_West-J/287",
      "name": "Atchuvely West-J/287",
      "ds": "Valikamam-East- Kopayi"
    },
    {
      "value": "gn:Karanavai_South-J/348",
      "name": "Karanavai South-J/348",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Karanavai_West-J/349",
      "name": "Karanavai West-J/349",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Karanavai-J/350",
      "name": "Karanavai-J/350",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Karanavai_East-J/351",
      "name": "Karanavai East-J/351",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Udupiddy-J/352",
      "name": "Udupiddy-J/352",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Udupiddy_North-J/353",
      "name": "Udupiddy North-J/353",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Udupiddy_South-J/354",
      "name": "Udupiddy South-J/354",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Valvetty-J/355",
      "name": "Valvetty-J/355",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Velvetty_Centre-J/356",
      "name": "Velvetty Centre-J/356",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Samarapaku-J/357",
      "name": "Samarapaku-J/357",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Imayanan-J/358",
      "name": "Imayanan-J/358",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Imayanan_West-J/359",
      "name": "Imayanan West-J/359",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Karanavai__North-J/360",
      "name": "Karanavai  North-J/360",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Karanavai_North_West-J/361",
      "name": "Karanavai North West-J/361",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Karanavai_Centre-J/362",
      "name": "Karanavai Centre-J/362",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Karaveddy_West-J/363",
      "name": "Karaveddy West-J/363",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Karaveddy_North-J/364",
      "name": "Karaveddy North-J/364",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Karaveddy_South-J/365",
      "name": "Karaveddy South-J/365",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Maththony-J/366",
      "name": "Maththony-J/366",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Karaveddy_Centre-J/367",
      "name": "Karaveddy Centre-J/367",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Karaveddy_East-J/368",
      "name": "Karaveddy East-J/368",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Kaddaiveli-J/369",
      "name": "Kaddaiveli-J/369",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Thunnalai_South-J/370",
      "name": "Thunnalai South-J/370",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Thunnalai_East-J/371",
      "name": "Thunnalai East-J/371",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Thunnalai-J/372",
      "name": "Thunnalai-J/372",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Thunnalai_Centre-J/373",
      "name": "Thunnalai Centre-J/373",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Thunnalai_West-J/374",
      "name": "Thunnalai West-J/374",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Nelliady_North-J/375",
      "name": "Nelliady North-J/375",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Nelliady-J/376",
      "name": "Nelliady-J/376",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Nellaidy_East-J/377",
      "name": "Nellaidy East-J/377",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Alvai-J/378",
      "name": "Alvai-J/378",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Alvai_South-J/379",
      "name": "Alvai South-J/379",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Alvai_East-J/380",
      "name": "Alvai East-J/380",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Aththai-J/381",
      "name": "Aththai-J/381",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Kapputhoo-J/382",
      "name": "Kapputhoo-J/382",
      "ds": "Vadamaradchy South-West, Karaveddy"
    },
    {
      "value": "gn:Manatkada-J/418",
      "name": "Manatkada-J/418",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Kudaththanai-J/419",
      "name": "Kudaththanai-J/419",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Kudaththanai_Karaiyoor-North-J/420",
      "name": "Kudaththanai Karaiyoor-North-J/420",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Potpathy-J/421",
      "name": "Potpathy-J/421",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Ampan-J/422",
      "name": "Ampan-J/422",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Nagarkovil_East-J/423",
      "name": "Nagarkovil East-J/423",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Nagarkovil_West-J/424",
      "name": "Nagarkovil West-J/424",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Nagarkovil_South-J/425",
      "name": "Nagarkovil South-J/425",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Chempianpattu_North-J/426",
      "name": "Chempianpattu North-J/426",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Chempianpattu_South-J/427",
      "name": "Chempianpattu South-J/427",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Maruthankerny-J/428",
      "name": "Maruthankerny-J/428",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Vaththirayan-J/429",
      "name": "Vaththirayan-J/429",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Uduththurai-J/430",
      "name": "Uduththurai-J/430",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Aliyavalai-J/431",
      "name": "Aliyavalai-J/431",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Vettilaikerny-J/432",
      "name": "Vettilaikerny-J/432",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Mullain-J/433",
      "name": "Mullain-J/433",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Pokkaruppu-J/434",
      "name": "Pokkaruppu-J/434",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Chundikulam-J/435",
      "name": "Chundikulam-J/435",
      "ds": "Vadamaradchi East-Maruthnkerny"
    },
    {
      "value": "gn:Thondamannaru_South-J/383",
      "name": "Thondamannaru South-J/383",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Thondamanmaru_North-J/384",
      "name": "Thondamanmaru North-J/384",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Karudavil_South-J/385",
      "name": "Karudavil South-J/385",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Karudavil_North-J/386",
      "name": "Karudavil North-J/386",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Karudavil_East-J/387",
      "name": "Karudavil East-J/387",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Valvettithurai_North_West-J/388",
      "name": "Valvettithurai North West-J/388",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Valvettithurai_North_Centre-J/389",
      "name": "Valvettithurai North Centre-J/389",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Valvettithurai_North_East-J/390",
      "name": "Valvettithurai North East-J/390",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Valvettithurai_South_West-J/391",
      "name": "Valvettithurai South West-J/391",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Valvettithurai_South_East-J/392",
      "name": "Valvettithurai South East-J/392",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Polikandy_West-J/393",
      "name": "Polikandy West-J/393",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Polikandy_East-J/394",
      "name": "Polikandy East-J/394",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Polikandy_South-J/395",
      "name": "Polikandy South-J/395",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Alvai_West-J/396",
      "name": "Alvai West-J/396",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Alvai_North_West-J/397",
      "name": "Alvai North West-J/397",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Alvai_North_Centre-J/398",
      "name": "Alvai North Centre-J/398",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Viyaparimoolai-J/399",
      "name": "Viyaparimoolai-J/399",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Alvai_North-J/400",
      "name": "Alvai North-J/400",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Point_Pedro-J/401",
      "name": "Point Pedro-J/401",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Point_Pedro_South-J/402",
      "name": "Point Pedro South-J/402",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Point_Pedro_East-J/403",
      "name": "Point Pedro East-J/403",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Thumpalai-J/404",
      "name": "Thumpalai-J/404",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Thumpalai_East-J/405",
      "name": "Thumpalai East-J/405",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Katkovalam-J/406",
      "name": "Katkovalam-J/406",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Puloly_North-J/407",
      "name": "Puloly North-J/407",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Puloly_North_East-J/408",
      "name": "Puloly North East-J/408",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Puloly_Centre-J/409",
      "name": "Puloly Centre-J/409",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Puloly_West-J/410",
      "name": "Puloly West-J/410",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Puloly_South_West-J/411",
      "name": "Puloly South West-J/411",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Puloly_North_West-J/412",
      "name": "Puloly North West-J/412",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Puloly_East-J/413",
      "name": "Puloly East-J/413",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Puloly_South-J/414",
      "name": "Puloly South-J/414",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Manthaikai-J/415",
      "name": "Manthaikai-J/415",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Vallipuram-J/416",
      "name": "Vallipuram-J/416",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Thunnali_North-J/417",
      "name": "Thunnali North-J/417",
      "ds": "Vadamaradchi North-Point Pedro"
    },
    {
      "value": "gn:Kaithady_North-J/288",
      "name": "Kaithady North-J/288",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kaithady_East-J/289",
      "name": "Kaithady East-J/289",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kaithady_Centre-J/290",
      "name": "Kaithady Centre-J/290",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kaithady_South-J/291",
      "name": "Kaithady South-J/291",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kaithady_South_East-J/292",
      "name": "Kaithady South East-J/292",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kaithady_West-J/293",
      "name": "Kaithady West-J/293",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Navatkuly_West-J/294",
      "name": "Navatkuly West-J/294",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Navatkuly_East-J/295",
      "name": "Navatkuly East-J/295",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kogilakandy-J/296",
      "name": "Kogilakandy-J/296",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kaithady_Navatkuly-J/297",
      "name": "Kaithady Navatkuly-J/297",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Maravanpulo-J/298",
      "name": "Maravanpulo-J/298",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Thanankillappu-J/299",
      "name": "Thanankillappu-J/299",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Chavakachcheri_Town-J/300",
      "name": "Chavakachcheri Town-J/300",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kovitkudyiruppu-J/301",
      "name": "Kovitkudyiruppu-J/301",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Sankathanai-J/302",
      "name": "Sankathanai-J/302",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Chavakachcheri_North-J/303",
      "name": "Chavakachcheri North-J/303",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Manduvil-J/304",
      "name": "Manduvil-J/304",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kalvayal-J/305",
      "name": "Kalvayal-J/305",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Nunavil_East-J/306",
      "name": "Nunavil East-J/306",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Nunavil_Centre-J/307",
      "name": "Nunavil Centre-J/307",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Nunavil_West-J/308",
      "name": "Nunavil West-J/308",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kaithady_Nunavil-_J/309",
      "name": "Kaithady Nunavil- J/309",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Themattuvil-J/310",
      "name": "Themattuvil-J/310",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Maddavil_Nunavil-J/311",
      "name": "Maddavil Nunavil-J/311",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Madduvil_Centre-J/312",
      "name": "Madduvil Centre-J/312",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Madduvil_North-J/313",
      "name": "Madduvil North-J/313",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Madduvil_East-J/314",
      "name": "Madduvil East-J/314",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Chandrapuram-J/315",
      "name": "Chandrapuram-J/315",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Sarasalai_South-J/316",
      "name": "Sarasalai South-J/316",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Sarasalai_North-J/317",
      "name": "Sarasalai North-J/317",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Meesalai_East-J/318",
      "name": "Meesalai East-J/318",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Meesalai_West-J/319",
      "name": "Meesalai West-J/319",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Ramavil-J/320",
      "name": "Ramavil-J/320",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Meesalai_North-J/321",
      "name": "Meesalai North-J/321",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Allaral-J/322",
      "name": "Allaral-J/322",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Vellampokkaddy-J/323",
      "name": "Vellampokkaddy-J/323",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kachchai-J/324",
      "name": "Kachchai-J/324",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Palavi-J/325",
      "name": "Palavi-J/325",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kodikamam_North-J/326",
      "name": "Kodikamam North-J/326",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kodikamam_Centre-J/327",
      "name": "Kodikamam Centre-J/327",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kodikamam_South-J/328",
      "name": "Kodikamam South-J/328",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Ushan-J/329",
      "name": "Ushan-J/329",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Karampaham-J/330",
      "name": "Karampaham-J/330",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Vidaththalpallai-J/331",
      "name": "Vidaththalpallai-J/331",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Ketpeli-J/332",
      "name": "Ketpeli-J/332",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Eluthumaadwarl_South-J/333",
      "name": "Eluthumaadwarl South-J/333",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Eluthumaadwart_North-J/334",
      "name": "Eluthumaadwart North-J/334",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Mirusuvil_North-J/335",
      "name": "Mirusuvil North-J/335",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Mirusuvil_South-J/336",
      "name": "Mirusuvil South-J/336",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Kudamiyan-J/337",
      "name": "Kudamiyan-J/337",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Navatkadu-J/338",
      "name": "Navatkadu-J/338",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Varany_North-J/339",
      "name": "Varany North-J/339",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Maseri-J/340",
      "name": "Maseri-J/340",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Idaikurichchi-J/341",
      "name": "Idaikurichchi-J/341",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Karampaikurichchi-J/342",
      "name": "Karampaikurichchi-J/342",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Varani_Iyattalai-J/343",
      "name": "Varani Iyattalai-J/343",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Thavalai_lyattalai-J/344",
      "name": "Thavalai lyattalai-J/344",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Manthuvil_East-J/345",
      "name": "Manthuvil East-J/345",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Manthuvil_West-J/346",
      "name": "Manthuvil West-J/346",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Manthuvil_North-J/347",
      "name": "Manthuvil North-J/347",
      "ds": "Thenmaradchi-Chavakachcheri"
    },
    {
      "value": "gn:Ariyalai_South_East-J/89",
      "name": "Ariyalai South East-J/89",
      "ds": "Nallur"
    },
    {
      "value": "gn:Aniyalai_East-J/90",
      "name": "Aniyalai East-J/90",
      "ds": "Nallur"
    },
    {
      "value": "gn:Ariyalai_North_West-J/91",
      "name": "Ariyalai North West-J/91",
      "ds": "Nallur"
    },
    {
      "value": "gn:Ariyalai_Centre_West-J/92",
      "name": "Ariyalai Centre West-J/92",
      "ds": "Nallur"
    },
    {
      "value": "gn:Anyalai_South_West-J/93",
      "name": "Anyalai South West-J/93",
      "ds": "Nallur"
    },
    {
      "value": "gn:Ariyalai_Centre_North-J/94",
      "name": "Ariyalai Centre North-J/94",
      "ds": "Nallur"
    },
    {
      "value": "gn:Ariyalai_Centre-J/95",
      "name": "Ariyalai Centre-J/95",
      "ds": "Nallur"
    },
    {
      "value": "gn:Ariyalai_Centre_South-J/96",
      "name": "Ariyalai Centre South-J/96",
      "ds": "Nallur"
    },
    {
      "value": "gn:Iyanarkovillady-J/97",
      "name": "Iyanarkovillady-J/97",
      "ds": "Nallur"
    },
    {
      "value": "gn:Vannarpannai_North-J/98",
      "name": "Vannarpannai North-J/98",
      "ds": "Nallur"
    },
    {
      "value": "gn:Vannarpannai_North_West-J/99",
      "name": "Vannarpannai North West-J/99",
      "ds": "Nallur"
    },
    {
      "value": "gn:Vannarpannai_North_East-J/100",
      "name": "Vannarpannai North East-J/100",
      "ds": "Nallur"
    },
    {
      "value": "gn:Neeraviyady-J/101",
      "name": "Neeraviyady-J/101",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kandarmadam_North_West-J/102",
      "name": "Kandarmadam North West-J/102",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kandarmadam_North_East-J/103",
      "name": "Kandarmadam North East-J/103",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kandarmadam_South_West-J/104",
      "name": "Kandarmadam South West-J/104",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kandarmadam_South_East-J/105",
      "name": "Kandarmadam South East-J/105",
      "ds": "Nallur"
    },
    {
      "value": "gn:Nallur_North-J/106",
      "name": "Nallur North-J/106",
      "ds": "Nallur"
    },
    {
      "value": "gn:Nallur_Rajathani-_J/107",
      "name": "Nallur Rajathani- J/107",
      "ds": "Nallur"
    },
    {
      "value": "gn:Nallur-J/108",
      "name": "Nallur-J/108",
      "ds": "Nallur"
    },
    {
      "value": "gn:Sangilian_Thoppu-J/109",
      "name": "Sangilian Thoppu-J/109",
      "ds": "Nallur"
    },
    {
      "value": "gn:Thirunelvely_West-J/110",
      "name": "Thirunelvely West-J/110",
      "ds": "Nallur"
    },
    {
      "value": "gn:Thirunelvely_Centre_South-J/111",
      "name": "Thirunelvely Centre South-J/111",
      "ds": "Nallur"
    },
    {
      "value": "gn:Thirunelvely_South_East-J/112",
      "name": "Thirunelvely South East-J/112",
      "ds": "Nallur"
    },
    {
      "value": "gn:Thirunelvely_North_East-J/113",
      "name": "Thirunelvely North East-J/113",
      "ds": "Nallur"
    },
    {
      "value": "gn:Thirunelvely_Centre_North-J/114",
      "name": "Thirunelvely Centre North-J/114",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kondavil_North_West-J/115",
      "name": "Kondavil North West-J/115",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kondasil_South_West-J/116",
      "name": "Kondasil South West-J/116",
      "ds": "Nallur"
    },
    {
      "value": "gn:Koodavil_Centre_West-J/117",
      "name": "Koodavil Centre West-J/117",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kondavil_Centre_East-J/118",
      "name": "Kondavil Centre East-J/118",
      "ds": "Nallur"
    },
    {
      "value": "gn:Koodavil_North_East-J/119",
      "name": "Koodavil North East-J/119",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kondavil_South_East-J/120",
      "name": "Kondavil South East-J/120",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kokkuvil_North_East-J/121",
      "name": "Kokkuvil North East-J/121",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kokkuvil_East-J/122",
      "name": "Kokkuvil East-J/122",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kokkuvil_South_East-J/123",
      "name": "Kokkuvil South East-J/123",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kokkuvil_North_West-J/124",
      "name": "Kokkuvil North West-J/124",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kokkuvil_West-J/125",
      "name": "Kokkuvil West-J/125",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kokkuvil_Centre_East-J/126",
      "name": "Kokkuvil Centre East-J/126",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kokkuvil_South_West-J/127",
      "name": "Kokkuvil South West-J/127",
      "ds": "Nallur"
    },
    {
      "value": "gn:Kokkuvil_Centre_West-J/128",
      "name": "Kokkuvil Centre West-J/128",
      "ds": "Nallur"
    },
    {
      "value": "gn:Nedunkulam-_J/61",
      "name": "Nedunkulam- J/61",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Columbuthurai_East-J/62",
      "name": "Columbuthurai East-J/62",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Columbutharai_West-J/63",
      "name": "Columbutharai West-J/63",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Passaiyoor_East-J/64",
      "name": "Passaiyoor East-J/64",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Passaiyoor_West-J/65",
      "name": "Passaiyoor West-J/65",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Eachchamoddai-_J/66",
      "name": "Eachchamoddai- J/66",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Thirumagar-J/67",
      "name": "Thirumagar-J/67",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Reclamation_East-J/68",
      "name": "Reclamation East-J/68",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Reclamation_West-J/69",
      "name": "Reclamation West-J/69",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Gurunagar_East-J/70",
      "name": "Gurunagar East-J/70",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Gurunagar_West-J/71",
      "name": "Gurunagar West-J/71",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Small_Bazzar-J/72",
      "name": "Small Bazzar-J/72",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Jaffna_Town_West-J/73",
      "name": "Jaffna Town West-J/73",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Jaffna_Town_East-J/74",
      "name": "Jaffna Town East-J/74",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Chundakali_South-J/75",
      "name": "Chundakali South-J/75",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Chundakul_North-J/76",
      "name": "Chundakul North-J/76",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Maruthady-J/77",
      "name": "Maruthady-J/77",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Aththiyady-J/78",
      "name": "Aththiyady-J/78",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Sirampiady-J/79",
      "name": "Sirampiady-J/79",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Grand_Bazzar-J/80",
      "name": "Grand Bazzar-J/80",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Fort-J/81",
      "name": "Fort-J/81",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Vannarponnai-J/82",
      "name": "Vannarponnai-J/82",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Koddady-J/83",
      "name": "Koddady-J/83",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Navanthurai_South-J/84",
      "name": "Navanthurai South-J/84",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Navanthurai_North-J/85",
      "name": "Navanthurai North-J/85",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Moor_Street_South-J/86",
      "name": "Moor Street South-J/86",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Moor_Street_North-J/87",
      "name": "Moor Street North-J/87",
      "ds": "Jaffna"
    },
    {
      "value": "gn:New_Moor_Street-J/88",
      "name": "New Moor Street-J/88",
      "ds": "Jaffna"
    },
    {
      "value": "gn:Mandaitivu_East-J/07",
      "name": "Mandaitivu East-J/07",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Mandaitivu_West-J/08",
      "name": "Mandaitivu West-J/08",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Mandaitivu_South-J/09",
      "name": "Mandaitivu South-J/09",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Allaipiddy-J/10",
      "name": "Allaipiddy-J/10",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Mankumpan-J/11",
      "name": "Mankumpan-J/11",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Velanai_North-J/12",
      "name": "Velanai North-J/12",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Velanai_North_East-J/13",
      "name": "Velanai North East-J/13",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Velanai_East-J/14",
      "name": "Velanai East-J/14",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Velanai_South_East-J/15",
      "name": "Velanai South East-J/15",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Velana_East_Centre-J/16",
      "name": "Velana East Centre-J/16",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Velanai_South-J/17",
      "name": "Velanai South-J/17",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Velanai_West_Centre-J/18",
      "name": "Velanai West Centre-J/18",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Velanai_West-J/19",
      "name": "Velanai West-J/19",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Saravanai_East-J/20",
      "name": "Saravanai East-J/20",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Saravanai_West-J/21",
      "name": "Saravanai West-J/21",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Pungudutivu_North_East-J/22",
      "name": "Pungudutivu North East-J/22",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Pungudutivu_East-J/23",
      "name": "Pungudutivu East-J/23",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Pungudutivu_South_East-J/24",
      "name": "Pungudutivu South East-J/24",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Pungudutivu_East_South-J/25",
      "name": "Pungudutivu East South-J/25",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Pungudutive_South-J/26",
      "name": "Pungudutive South-J/26",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Pungudative_North-J/27",
      "name": "Pungudative North-J/27",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Pungudutivu_Centre_North-J/28",
      "name": "Pungudutivu Centre North-J/28",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Pungudutivu_South_West-J/29",
      "name": "Pungudutivu South West-J/29",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Punguditivu_Centre_West-J/30",
      "name": "Punguditivu Centre West-J/30",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Punguditivu_Centre_East-J/31",
      "name": "Punguditivu Centre East-J/31",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Pungudutivu_North_West-J/32",
      "name": "Pungudutivu North West-J/32",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Pungudutivu_West-J/33",
      "name": "Pungudutivu West-J/33",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Nainativu_North-J/34",
      "name": "Nainativu North-J/34",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Nainativu_Centre-J/35",
      "name": "Nainativu Centre-J/35",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Nainativu_South-J/36",
      "name": "Nainativu South-J/36",
      "ds": "Island South,Velanai"
    },
    {
      "value": "gn:Delft_West-J/01",
      "name": "Delft West-J/01",
      "ds": "Delft"
    },
    {
      "value": "gn:Delft_South-J/02",
      "name": "Delft South-J/02",
      "ds": "Delft"
    },
    {
      "value": "gn:Delft_Centre_West-J/03",
      "name": "Delft Centre West-J/03",
      "ds": "Delft"
    },
    {
      "value": "gn:Delft_Centre-J/04",
      "name": "Delft Centre-J/04",
      "ds": "Delft"
    },
    {
      "value": "gn:Delft_Centre_East-J/05",
      "name": "Delft Centre East-J/05",
      "ds": "Delft"
    },
    {
      "value": "gn:Delft_East-J/06",
      "name": "Delft East-J/06",
      "ds": "Delft"
    },
    {
      "value": "gn:Karainagar_West-J/40",
      "name": "Karainagar West-J/40",
      "ds": "Karainagar"
    },
    {
      "value": "gn:Karainagar_North_West-J/41",
      "name": "Karainagar North West-J/41",
      "ds": "Karainagar"
    },
    {
      "value": "gn:Karainagar_East-J/42",
      "name": "Karainagar East-J/42",
      "ds": "Karainagar"
    },
    {
      "value": "gn:Karainagar_South_East-J/43",
      "name": "Karainagar South East-J/43",
      "ds": "Karainagar"
    },
    {
      "value": "gn:Karainagar_South-J/44",
      "name": "Karainagar South-J/44",
      "ds": "Karainagar"
    },
    {
      "value": "gn:Karainagar_South_West-J/45",
      "name": "Karainagar South West-J/45",
      "ds": "Karainagar"
    },
    {
      "value": "gn:Karainagar_North-J/46",
      "name": "Karainagar North-J/46",
      "ds": "Karainagar"
    },
    {
      "value": "gn:Karainagar_North_East-J/47",
      "name": "Karainagar North East-J/47",
      "ds": "Karainagar"
    },
    {
      "value": "gn:Karainagar_Centre-J/48",
      "name": "Karainagar Centre-J/48",
      "ds": "Karainagar"
    },
    {
      "value": "gn:Talaimannar_Village_North-MNR/48",
      "name": "Talaimannar Village North-MNR/48",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Talaimannur_Village_South-MNR/49",
      "name": "Talaimannur Village South-MNR/49",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Talaimannar_pier_West-MNR/50",
      "name": "Talaimannar pier West-MNR/50",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Talaimannar_pier_East-MNR/51",
      "name": "Talaimannar pier East-MNR/51",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Talaimannar-MNR/52",
      "name": "Talaimannar-MNR/52",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Kaddukarankudiyiruppu-MNR/53",
      "name": "Kaddukarankudiyiruppu-MNR/53",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Thullukudiyiruppu-MNR/54",
      "name": "Thullukudiyiruppu-MNR/54",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Pesalai_west-MNR/55",
      "name": "Pesalai west-MNR/55",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Pesalai_South-MNR/56",
      "name": "Pesalai South-MNR/56",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Pesalai_North-MNR/57",
      "name": "Pesalai North-MNR/57",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Siruthoppu-MNR/58",
      "name": "Siruthoppu-MNR/58",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Periya_kerisal-MNR/59",
      "name": "Periya kerisal-MNR/59",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Olaithodduwai-MNR/60",
      "name": "Olaithodduwai-MNR/60",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Pthukudiyiruppu-MNR/61",
      "name": "Pthukudiyiruppu-MNR/61",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Thoddawely-MNR/62",
      "name": "Thoddawely-MNR/62",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Erukkalampiddy_West-MNR/63",
      "name": "Erukkalampiddy West-MNR/63",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Erukkalampiddy_North-MNR/64",
      "name": "Erukkalampiddy North-MNR/64",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Erukkalampiddy_East-MNR/65",
      "name": "Erukkalampiddy East-MNR/65",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Erukkalampiddy_South-MNR/66",
      "name": "Erukkalampiddy South-MNR/66",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Erukkalampiddy_Central-MNR/67",
      "name": "Erukkalampiddy Central-MNR/67",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Tharapuram_west-MNR/68",
      "name": "Tharapuram west-MNR/68",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Tharapuram_East-MNR/69",
      "name": "Tharapuram East-MNR/69",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Thalvpadu-MNR/70",
      "name": "Thalvpadu-MNR/70",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Pattim-MNR/71",
      "name": "Pattim-MNR/71",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Eluthoor-MNR/72",
      "name": "Eluthoor-MNR/72",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:South_bar-MNR/73",
      "name": "South bar-MNR/73",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Emil_Nagar-MNR/74",
      "name": "Emil Nagar-MNR/74",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Chavatkaddu-MNR/75",
      "name": "Chavatkaddu-MNR/75",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Panankaddikoddu_west-MNR/76",
      "name": "Panankaddikoddu west-MNR/76",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Panankaddikoddu_East-MNR/77",
      "name": "Panankaddikoddu East-MNR/77",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Sinnakadai-MNR/78",
      "name": "Sinnakadai-MNR/78",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Pettah-MNR/79",
      "name": "Pettah-MNR/79",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Periyakadai-MNR/80",
      "name": "Periyakadai-MNR/80",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Moor_Street-MNR/81",
      "name": "Moor Street-MNR/81",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Uppukulum_North-MNR/82",
      "name": "Uppukulum North-MNR/82",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Uppukulam_South-MNR/83",
      "name": "Uppukulam South-MNR/83",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Pallimunai_East-MNR/84",
      "name": "Pallimunai East-MNR/84",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Pallimunai_West-MNR/85",
      "name": "Pallimunai West-MNR/85",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Thiruketheeswaram-MNR/86",
      "name": "Thiruketheeswaram-MNR/86",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Periyanavatkulam-MNR/87",
      "name": "Periyanavatkulam-MNR/87",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Nagathalvu-MNR/88",
      "name": "Nagathalvu-MNR/88",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Neelasenai-MNR/89",
      "name": "Neelasenai-MNR/89",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Kallikaddaskadu-MNR/90",
      "name": "Kallikaddaskadu-MNR/90",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Puthakamam-MNR/91",
      "name": "Puthakamam-MNR/91",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Uyilankulam-MNR/92",
      "name": "Uyilankulam-MNR/92",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Mathoddam-MNR/93",
      "name": "Mathoddam-MNR/93",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Vannamoddai-MNR/94",
      "name": "Vannamoddai-MNR/94",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Uyirtharasankulam-MNR/95",
      "name": "Uyirtharasankulam-MNR/95",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Parappankandal-MNR/96",
      "name": "Parappankandal-MNR/96",
      "ds": "Mannar Town"
    },
    {
      "value": "gn:Vellankulam-MNR/01",
      "name": "Vellankulam-MNR/01",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Thevanpiddy-MNR/02",
      "name": "Thevanpiddy-MNR/02",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Pali_Aru-MNR/03",
      "name": "Pali Aru-MNR/03",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Illuppaikadavai-MNR/04",
      "name": "Illuppaikadavai-MNR/04",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Anthoniyarpuram-MNR/05",
      "name": "Anthoniyarpuram-MNR/05",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Kalliyady-MNR/06",
      "name": "Kalliyady-MNR/06",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Kurai-MNR/07",
      "name": "Kurai-MNR/07",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Aththimoddai-MNR/08",
      "name": "Aththimoddai-MNR/08",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Kovitkulam-MNR/09",
      "name": "Kovitkulam-MNR/09",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Vidataltivu_West-MNR/10",
      "name": "Vidataltivu West-MNR/10",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Vidataltivu_North-MNR/11",
      "name": "Vidataltivu North-MNR/11",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Vidataltivu_Central-MNR/12",
      "name": "Vidataltivu Central-MNR/12",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Vidataltivu_East-MNR/13",
      "name": "Vidataltivu East-MNR/13",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Pallamalhu-MNR/14",
      "name": "Pallamalhu-MNR/14",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Kaya_Nagar-MNR/15",
      "name": "Kaya Nagar-MNR/15",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Periyamadhu_West-MNR/16",
      "name": "Periyamadhu West-MNR/16",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Periyamadhu_East-MNR/17",
      "name": "Periyamadhu East-MNR/17",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Pappamoddai-MNR/18",
      "name": "Pappamoddai-MNR/18",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Veddayamurippu-MNR/19",
      "name": "Veddayamurippu-MNR/19",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Minukkan-MNR/20",
      "name": "Minukkan-MNR/20",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Maligaithiddal-MNR/21",
      "name": "Maligaithiddal-MNR/21",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Adampan-MNR/22",
      "name": "Adampan-MNR/22",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Palaikuly-MNR/23",
      "name": "Palaikuly-MNR/23",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Nedunkandal-MNR/24",
      "name": "Nedunkandal-MNR/24",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Sornapuri-MNR/25",
      "name": "Sornapuri-MNR/25",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Karunkandal-MNR/26",
      "name": "Karunkandal-MNR/26",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Andankulam-MNR/27",
      "name": "Andankulam-MNR/27",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Kannady-MNR/28",
      "name": "Kannady-MNR/28",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Alkaddively-MNR/29",
      "name": "Alkaddively-MNR/29",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Kannkandal_Vannakulam-MNR/30",
      "name": "Kannkandal Vannakulam-MNR/30",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Kaththankalam-MNR/31",
      "name": "Kaththankalam-MNR/31",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Vaddakandal-MNR/32",
      "name": "Vaddakandal-MNR/32",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Palai_Periymalkaddu-MNR/33",
      "name": "Palai Periymalkaddu-MNR/33",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Palaiyadi_Puthukulam-MNR/34",
      "name": "Palaiyadi Puthukulam-MNR/34",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Parappukadanthan_west-MNR/35",
      "name": "Parappukadanthan west-MNR/35",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Parappukadanthan_East-MNR/36",
      "name": "Parappukadanthan East-MNR/36",
      "ds": "Manthai West"
    },
    {
      "value": "gn:Madhu-MNR/37",
      "name": "Madhu-MNR/37",
      "ds": "Madhu"
    },
    {
      "value": "gn:Periya_Pandiviruchchan_West-MNR/38",
      "name": "Periya Pandiviruchchan West-MNR/38",
      "ds": "Madhu"
    },
    {
      "value": "gn:Periya_Pandiviruchchan_East-MNR/39",
      "name": "Periya Pandiviruchchan East-MNR/39",
      "ds": "Madhu"
    },
    {
      "value": "gn:Palampiddy-MNR/40",
      "name": "Palampiddy-MNR/40",
      "ds": "Madhu"
    },
    {
      "value": "gn:Keerisuddan-MNR/41",
      "name": "Keerisuddan-MNR/41",
      "ds": "Madhu"
    },
    {
      "value": "gn:Iranai_Illuppaikulam-MNR/42",
      "name": "Iranai Illuppaikulam-MNR/42",
      "ds": "Madhu"
    },
    {
      "value": "gn:Vilaththikulam-MNR/43",
      "name": "Vilaththikulam-MNR/43",
      "ds": "Madhu"
    },
    {
      "value": "gn:Parasankalam-MNR/44",
      "name": "Parasankalam-MNR/44",
      "ds": "Madhu"
    },
    {
      "value": "gn:Kakkayankulam_West-MNR/45",
      "name": "Kakkayankulam West-MNR/45",
      "ds": "Madhu"
    },
    {
      "value": "gn:Kakkayankulam_East-MNR/46",
      "name": "Kakkayankulam East-MNR/46",
      "ds": "Madhu"
    },
    {
      "value": "gn:Kalmadhu-MNR/47",
      "name": "Kalmadhu-MNR/47",
      "ds": "Madhu"
    },
    {
      "value": "gn:Maluvarayar_Kaddaiyadumpan-MNR/128",
      "name": "Maluvarayar Kaddaiyadumpan-MNR/128",
      "ds": "Madhu"
    },
    {
      "value": "gn:Pannavedduvan-MNR/129",
      "name": "Pannavedduvan-MNR/129",
      "ds": "Madhu"
    },
    {
      "value": "gn:Pomalarnthan-MNR/130",
      "name": "Pomalarnthan-MNR/130",
      "ds": "Madhu"
    },
    {
      "value": "gn:Thekkam-MNR/131",
      "name": "Thekkam-MNR/131",
      "ds": "Madhu"
    },
    {
      "value": "gn:Matha_Gramam-MNR/132",
      "name": "Matha Gramam-MNR/132",
      "ds": "Madhu"
    },
    {
      "value": "gn:Periyamurippu-MNR/133",
      "name": "Periyamurippu-MNR/133",
      "ds": "Madhu"
    },
    {
      "value": "gn:Vankalai_North-MNR/97",
      "name": "Vankalai North-MNR/97",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Vankalai_west-MNR/98",
      "name": "Vankalai west-MNR/98",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Vankalai_east-MNR/99",
      "name": "Vankalai east-MNR/99",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Thomaspuri-MNR/100",
      "name": "Thomaspuri-MNR/100",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Naruvilikalam-MNR/101",
      "name": "Naruvilikalam-MNR/101",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Vanchchiankulam-MNR/102",
      "name": "Vanchchiankulam-MNR/102",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Umanagari-MNR/103",
      "name": "Umanagari-MNR/103",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Achchankulam-MNR/104",
      "name": "Achchankulam-MNR/104",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Rasamadhu-MNR/105",
      "name": "Rasamadhu-MNR/105",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Moddaikadal-MNR/106",
      "name": "Moddaikadal-MNR/106",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Nanaddan-MNR/107",
      "name": "Nanaddan-MNR/107",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Pallankoddai-MNR/108",
      "name": "Pallankoddai-MNR/108",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Valkaipadankandal-MNR/109",
      "name": "Valkaipadankandal-MNR/109",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Periyakadlaikadu-MNR/110",
      "name": "Periyakadlaikadu-MNR/110",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Illahadipiddy-MNR/111",
      "name": "Illahadipiddy-MNR/111",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Illanthaimoddai-MNR/112",
      "name": "Illanthaimoddai-MNR/112",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Puthirakanidan-MNR/113",
      "name": "Puthirakanidan-MNR/113",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Razool_puthuvely-MNR/114",
      "name": "Razool puthuvely-MNR/114",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Kanchchithalvu-MNR/115",
      "name": "Kanchchithalvu-MNR/115",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Aththukuly-MNR/116",
      "name": "Aththukuly-MNR/116",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Chemmantivu-MNR/117",
      "name": "Chemmantivu-MNR/117",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Murangan-MNR/118",
      "name": "Murangan-MNR/118",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Chundukuly-MNR/119",
      "name": "Chundukuly-MNR/119",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Sirukandal-MNR/120",
      "name": "Sirukandal-MNR/120",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Pontheevukandal-MNR/121",
      "name": "Pontheevukandal-MNR/121",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Kalimoddai_Paliyankulam-MNR/122",
      "name": "Kalimoddai Paliyankulam-MNR/122",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Paritharikandal-MNR/123",
      "name": "Paritharikandal-MNR/123",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Katkidanthakulam-MNR/124",
      "name": "Katkidanthakulam-MNR/124",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Irraddaikulam-MNR/125",
      "name": "Irraddaikulam-MNR/125",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Cheddiamagan_Kaddaiyadampan-MNR/126",
      "name": "Cheddiamagan Kaddaiyadampan-MNR/126",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Isamalaithalvu-MNR/127",
      "name": "Isamalaithalvu-MNR/127",
      "ds": "Nanaddan"
    },
    {
      "value": "gn:Arippu_west-MNR/134",
      "name": "Arippu west-MNR/134",
      "ds": "Musalai"
    },
    {
      "value": "gn:Arippu_east-MNR/135",
      "name": "Arippu east-MNR/135",
      "ds": "Musalai"
    },
    {
      "value": "gn:Meththanvely-MNR/136",
      "name": "Meththanvely-MNR/136",
      "ds": "Musalai"
    },
    {
      "value": "gn:Pandaravely-MNR/137",
      "name": "Pandaravely-MNR/137",
      "ds": "Musalai"
    },
    {
      "value": "gn:Poonochchikulam-MNR/138",
      "name": "Poonochchikulam-MNR/138",
      "ds": "Musalai"
    },
    {
      "value": "gn:Maruthamadu-MNR/139",
      "name": "Maruthamadu-MNR/139",
      "ds": "Musalai"
    },
    {
      "value": "gn:Veppunkulam-MNR/140",
      "name": "Veppunkulam-MNR/140",
      "ds": "Musalai"
    },
    {
      "value": "gn:Periya_Pillachchi_Pokerny-MNR/141",
      "name": "Periya Pillachchi Pokerny-MNR/141",
      "ds": "Musalai"
    },
    {
      "value": "gn:Sinna_pullachchi_potkerny-MNR/142",
      "name": "Sinna pullachchi potkerny-MNR/142",
      "ds": "Musalai"
    },
    {
      "value": "gn:Ahathymurippu-MNR/143",
      "name": "Ahathymurippu-MNR/143",
      "ds": "Musalai"
    },
    {
      "value": "gn:Chilavathurai-MNR/144",
      "name": "Chilavathurai-MNR/144",
      "ds": "Musalai"
    },
    {
      "value": "gn:Saveriyarpuram-MNR/145",
      "name": "Saveriyarpuram-MNR/145",
      "ds": "Musalai"
    },
    {
      "value": "gn:Puthuvely-MNR/146",
      "name": "Puthuvely-MNR/146",
      "ds": "Musalai"
    },
    {
      "value": "gn:Koolankulam-MNR/147",
      "name": "Koolankulam-MNR/147",
      "ds": "Musalai"
    },
    {
      "value": "gn:Kokkupadayan-MNR/148",
      "name": "Kokkupadayan-MNR/148",
      "ds": "Musalai"
    },
    {
      "value": "gn:Kondachchi-MNR/149",
      "name": "Kondachchi-MNR/149",
      "ds": "Musalai"
    },
    {
      "value": "gn:Karadikuly-MNR/150",
      "name": "Karadikuly-MNR/150",
      "ds": "Musalai"
    },
    {
      "value": "gn:Marichchukaddi-MNR/151",
      "name": "Marichchukaddi-MNR/151",
      "ds": "Musalai"
    },
    {
      "value": "gn:Palaikuly-MNR/152",
      "name": "Palaikuly-MNR/152",
      "ds": "Musalai"
    },
    {
      "value": "gn:Mallikulam-MNR/153",
      "name": "Mallikulam-MNR/153",
      "ds": "Musalai"
    },
    {
      "value": "gn:Unchalkaddy-221",
      "name": "Unchalkaddy-221",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Velivaithakallu-221A",
      "name": "Velivaithakallu-221A",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Maruthodai-221B",
      "name": "Maruthodai-221B",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Paddikudiyiruppu-221C",
      "name": "Paddikudiyiruppu-221C",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Katkulam-221D",
      "name": "Katkulam-221D",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Nedunkerny_South-222",
      "name": "Nedunkerny South-222",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Nedunkerny_North-222A",
      "name": "Nedunkerny North-222A",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Olumadu-222B",
      "name": "Olumadu-222B",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Mamadu-222C",
      "name": "Mamadu-222C",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Kulavisuddan-222D",
      "name": "Kulavisuddan-222D",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Maraillupai-222E",
      "name": "Maraillupai-222E",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Paranthan-223",
      "name": "Paranthan-223",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Ananthapuliyankalam-223A",
      "name": "Ananthapuliyankalam-223A",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Sinnaadampan-223B",
      "name": "Sinnaadampan-223B",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Nainamadu-223C",
      "name": "Nainamadu-223C",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Puliyankalam_South-224",
      "name": "Puliyankalam South-224",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Puliyankalam_North-224A",
      "name": "Puliyankalam North-224A",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Kanagarayankulam_South-225",
      "name": "Kanagarayankulam South-225",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Kanagarayankulam_North-225A",
      "name": "Kanagarayankulam North-225A",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Mannakulam-225B",
      "name": "Mannakulam-225B",
      "ds": "Vavuniya North"
    },
    {
      "value": "gn:Periya_Ulukulama-C209",
      "name": "Periya Ulukulama-C209",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Poomaduwa-C209A",
      "name": "Poomaduwa-C209A",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Track-7-C209B",
      "name": "Track-7-C209B",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Marathanmadawa-C209C",
      "name": "Marathanmadawa-C209C",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Rangethgama-C209D",
      "name": "Rangethgama-C209D",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Avarantulawa-C209E",
      "name": "Avarantulawa-C209E",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Acre_20,40,_60-C209F",
      "name": "Acre 20,40, 60-C209F",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Acre_400.L.B._Coloni-C209G",
      "name": "Acre 400.L.B. Coloni-C209G",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Madukanda-C212",
      "name": "Madukanda-C212",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Erattaperiyakulam-C212A",
      "name": "Erattaperiyakulam-C212A",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Alagalla-C212B",
      "name": "Alagalla-C212B",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Avusadapitiya-C212C",
      "name": "Avusadapitiya-C212C",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Kalukkunamaduwa-C212D",
      "name": "Kalukkunamaduwa-C212D",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Nedunkalama-C212E",
      "name": "Nedunkalama-C212E",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Mahamylankulam-C212F",
      "name": "Mahamylankulam-C212F",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Mamaduwa-C213",
      "name": "Mamaduwa-C213",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Mahakachakodiya-C213A",
      "name": "Mahakachakodiya-C213A",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Puthubulankalama-C213B",
      "name": "Puthubulankalama-C213B",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Pirappanmaduwa-C213C",
      "name": "Pirappanmaduwa-C213C",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Agbopura-C213D",
      "name": "Agbopura-C213D",
      "ds": "Vavuniya South"
    },
    {
      "value": "gn:Vavuniya_Town-214",
      "name": "Vavuniya Town-214",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Thandikulam-214A",
      "name": "Thandikulam-214A",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Paddanichchipuliyankulam-214B",
      "name": "Paddanichchipuliyankulam-214B",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Vavuniya_North-214C",
      "name": "Vavuniya North-214C",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Vairavapuliyan_kalam-214D",
      "name": "Vairavapuliyan kalam-214D",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Pandarikulam-214E",
      "name": "Pandarikulam-214E",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Thonikal-214F",
      "name": "Thonikal-214F",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Moonrumurippu-214G",
      "name": "Moonrumurippu-214G",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Nochchimoddai-215",
      "name": "Nochchimoddai-215",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Maharamhaikulam-215A",
      "name": "Maharamhaikulam-215A",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Katharsinnakulam-215B",
      "name": "Katharsinnakulam-215B",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Kallikulam-215C",
      "name": "Kallikulam-215C",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Puthkulam-216",
      "name": "Puthkulam-216",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Paranaddankal-216A",
      "name": "Paranaddankal-216A",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Rajendrankulam-217",
      "name": "Rajendrankulam-217",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Poovarasankulam-217A",
      "name": "Poovarasankulam-217A",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Salampaikulam-217B",
      "name": "Salampaikulam-217B",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Chekkaddipulavu-217C",
      "name": "Chekkaddipulavu-217C",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Velankulam-217D",
      "name": "Velankulam-217D",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Nelukulam-218",
      "name": "Nelukulam-218",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Pampaimada-218A",
      "name": "Pampaimada-218A",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Kalmadu-218B",
      "name": "Kalmadu-218B",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Marakkaranpalai-218C",
      "name": "Marakkaranpalai-218C",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Sasthirikoolankulam-218D",
      "name": "Sasthirikoolankulam-218D",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Eachchankulam-218E",
      "name": "Eachchankulam-218E",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Koomankulam-218F",
      "name": "Koomankulam-218F",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Kanthapuram-218G",
      "name": "Kanthapuram-218G",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Maruthamadu-219",
      "name": "Maruthamadu-219",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Palamoddai-219A",
      "name": "Palamoddai-219A",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Maruthankulam-220",
      "name": "Maruthankulam-220",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Semamadu-220A",
      "name": "Semamadu-220A",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Malligai-220B",
      "name": "Malligai-220B",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Pantrikeythakulam-220C",
      "name": "Pantrikeythakulam-220C",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Omanthai-220D",
      "name": "Omanthai-220D",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Arumugathanouthukulam-220E",
      "name": "Arumugathanouthukulam-220E",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Mahilankulam-220F",
      "name": "Mahilankulam-220F",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Elamaruthankulam-220G",
      "name": "Elamaruthankulam-220G",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Asikulam-244",
      "name": "Asikulam-244",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Rambaikulam-244A",
      "name": "Rambaikulam-244A",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Samalankulam-244B",
      "name": "Samalankulam-244B",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Kovilkulam-244C",
      "name": "Kovilkulam-244C",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Velikulam-244D",
      "name": "Velikulam-244D",
      "ds": "Vavuniya"
    },
    {
      "value": "gn:Andiyapuliyankulam-207",
      "name": "Andiyapuliyankulam-207",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Periyathampanai-207A",
      "name": "Periyathampanai-207A",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Periyakadu-207B",
      "name": "Periyakadu-207B",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Kannady-207C",
      "name": "Kannady-207C",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Piramanalankulam-207D",
      "name": "Piramanalankulam-207D",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Sooduventhapulava-208",
      "name": "Sooduventhapulava-208",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Kurukkalputhukulam-208A",
      "name": "Kurukkalputhukulam-208A",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Kamhavamy_Nagar-208B",
      "name": "Kamhavamy Nagar-208B",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Pavatkulam_unit_5_&_6-208C",
      "name": "Pavatkulam unit 5 & 6-208C",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Pavatkulam_Unit_4-208D",
      "name": "Pavatkulam Unit 4-208D",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Pavatkulam_unit_2-208E",
      "name": "Pavatkulam unit 2-208E",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Muthaliyakalam-210",
      "name": "Muthaliyakalam-210",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Sinnasippikalum-210A",
      "name": "Sinnasippikalum-210A",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Neriyakulam-210B",
      "name": "Neriyakulam-210B",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Periyapuliyankulam-211",
      "name": "Periyapuliyankulam-211",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Cheddikulam-211A",
      "name": "Cheddikulam-211A",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Muhathankulam-211B",
      "name": "Muhathankulam-211B",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Pavatkulam_Unit_9_&_10-211C",
      "name": "Pavatkulam Unit 9 & 10-211C",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Christthokulam-211D",
      "name": "Christthokulam-211D",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Kankankulam-211E",
      "name": "Kankankulam-211E",
      "ds": "Vengalacheddikulam"
    },
    {
      "value": "gn:Thunukkai-MUL/16",
      "name": "Thunukkai-MUL/16",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Kalvilan-MUL/17",
      "name": "Kalvilan-MUL/17",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Uyilakulam-MUL/18",
      "name": "Uyilakulam-MUL/18",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Yogapurum_West-MUL/19",
      "name": "Yogapurum West-MUL/19",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Mallavi-MUL/20",
      "name": "Mallavi-MUL/20",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Yogapuram_Centre-MUL/21",
      "name": "Yogapuram Centre-MUL/21",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Thirunagar-MUL/22",
      "name": "Thirunagar-MUL/22",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Puthuvedduvan-MUL/23",
      "name": "Puthuvedduvan-MUL/23",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Amaithipuram-MUL/24",
      "name": "Amaithipuram-MUL/24",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Amplapperumalkulam-MUL/25",
      "name": "Amplapperumalkulam-MUL/25",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Alankulam-MUL/26",
      "name": "Alankulam-MUL/26",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Therkandal-MUL/27",
      "name": "Therkandal-MUL/27",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Yogapurum_East-MUL/28",
      "name": "Yogapurum East-MUL/28",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Pugalenthynagar-MUL/29",
      "name": "Pugalenthynagar-MUL/29",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Barathinagar-MUL/30",
      "name": "Barathinagar-MUL/30",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Anichchiyankulam-MUL/31",
      "name": "Anichchiyankulam-MUL/31",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Thenniyankulam-MUL/32",
      "name": "Thenniyankulam-MUL/32",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Palayamarkandy-MUL/33",
      "name": "Palayamarkandy-MUL/33",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Iyankulam-MUL/34",
      "name": "Iyankulam-MUL/34",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Koddaikaddiyakulam-MUL/35",
      "name": "Koddaikaddiyakulam-MUL/35",
      "ds": "Thunukkai"
    },
    {
      "value": "gn:Vannivilankulam-MUL/1",
      "name": "Vannivilankulam-MUL/1",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Ampalpuram-MUL/2",
      "name": "Ampalpuram-MUL/2",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Kollavilankulam-MUL/3",
      "name": "Kollavilankulam-MUL/3",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Oddaruthakulam-MUL/4",
      "name": "Oddaruthakulam-MUL/4",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Sivapuram-MUL/5",
      "name": "Sivapuram-MUL/5",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Palinagar-MUL/6",
      "name": "Palinagar-MUL/6",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Karumpulliyan-MUL/7",
      "name": "Karumpulliyan-MUL/7",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Poovarasankulam-MUL/8",
      "name": "Poovarasankulam-MUL/8",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Pandiyankulam-MUL/9",
      "name": "Pandiyankulam-MUL/9",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Selvapuram-MUL/10",
      "name": "Selvapuram-MUL/10",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Moonrumurippu-MUL/11",
      "name": "Moonrumurippu-MUL/11",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Naddunkandal-MUL/12",
      "name": "Naddunkandal-MUL/12",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Vinayagapuram-MUL/13",
      "name": "Vinayagapuram-MUL/13",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Ponnagar-MUL/14",
      "name": "Ponnagar-MUL/14",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Siraddikulam-MUL/15",
      "name": "Siraddikulam-MUL/15",
      "ds": "Manthai East"
    },
    {
      "value": "gn:Puthukkudiyiruppu_East-MUL/36",
      "name": "Puthukkudiyiruppu East-MUL/36",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Sivanagar-MUL/37",
      "name": "Sivanagar-MUL/37",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Manthuvil-MUL/38",
      "name": "Manthuvil-MUL/38",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Malligaitheevu-MUL/39",
      "name": "Malligaitheevu-MUL/39",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Ananthapuram-MUL/40",
      "name": "Ananthapuram-MUL/40",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Puthukkudiyiruppu_West-MUL/41",
      "name": "Puthukkudiyiruppu West-MUL/41",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Kombavil-MUL/42",
      "name": "Kombavil-MUL/42",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Iranaipalai-MUL/43",
      "name": "Iranaipalai-MUL/43",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Thevipuram-MUL/44",
      "name": "Thevipuram-MUL/44",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Vallipuram-MUL/45",
      "name": "Vallipuram-MUL/45",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Udayarkaddu_North-MUL/46",
      "name": "Udayarkaddu North-MUL/46",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Udayarkaddu_South-MUL/47",
      "name": "Udayarkaddu South-MUL/47",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Suthanthirapuram-MUL/48",
      "name": "Suthanthirapuram-MUL/48",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Visvamadu_west-MUL/49",
      "name": "Visvamadu west-MUL/49",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Visvamadu_East-MUL/50",
      "name": "Visvamadu East-MUL/50",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Manikkaporam-MUL/51",
      "name": "Manikkaporam-MUL/51",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Theravil-MUL/52",
      "name": "Theravil-MUL/52",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Vallavarpuram-MUL/53",
      "name": "Vallavarpuram-MUL/53",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Manakandal-MUL/54",
      "name": "Manakandal-MUL/54",
      "ds": "Puthukudiyiruppu"
    },
    {
      "value": "gn:Muththayankaddu_Kulam-MUL/55",
      "name": "Muththayankaddu Kulam-MUL/55",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Kanagaratnapuram-MUL/56",
      "name": "Kanagaratnapuram-MUL/56",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Peraru-MUL/57",
      "name": "Peraru-MUL/57",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Pandaravanni-MUL/58",
      "name": "Pandaravanni-MUL/58",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Katchilaimadu-MUL/59",
      "name": "Katchilaimadu-MUL/59",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Muththuvinayagapuram-MUL/60",
      "name": "Muththuvinayagapuram-MUL/60",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Oddusuddan-MUL/61",
      "name": "Oddusuddan-MUL/61",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Kathaliyar_Sumalankulum-MUL/62",
      "name": "Kathaliyar Sumalankulum-MUL/62",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Thaddayamalai-MUL/63",
      "name": "Thaddayamalai-MUL/63",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Ganeshapuram-MUL/64",
      "name": "Ganeshapuram-MUL/64",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Vithiyapuram-MUL/65",
      "name": "Vithiyapuram-MUL/65",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Karavelankandal-MUL/66",
      "name": "Karavelankandal-MUL/66",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Puliyankulam-MUL/67",
      "name": "Puliyankulam-MUL/67",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Koolamurippu-MUL/68",
      "name": "Koolamurippu-MUL/68",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Manavalanddamppu-MUL/69",
      "name": "Manavalanddamppu-MUL/69",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Thachchadampan-MUL/70",
      "name": "Thachchadampan-MUL/70",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Olumadu-MUL/71",
      "name": "Olumadu-MUL/71",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Ampugaman-MUL/72",
      "name": "Ampugaman-MUL/72",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Mankulam-MUL/73",
      "name": "Mankulam-MUL/73",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Panikkankulam-MUL/74",
      "name": "Panikkankulam-MUL/74",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Thirumurukandy-MUL/75",
      "name": "Thirumurukandy-MUL/75",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Inthupuram-MUL/76",
      "name": "Inthupuram-MUL/76",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Oththimalai-MUL/123",
      "name": "Oththimalai-MUL/123",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Periyakalam-MUL/124",
      "name": "Periyakalam-MUL/124",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Thanduwan-MUL/125",
      "name": "Thanduwan-MUL/125",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Periyalthimadu-MUL/126",
      "name": "Periyalthimadu-MUL/126",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Palampasi-MUL/127",
      "name": "Palampasi-MUL/127",
      "ds": "Oddusuddan"
    },
    {
      "value": "gn:Kokulai_west-MUL/77",
      "name": "Kokulai west-MUL/77",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kokulai_East-MUL/78",
      "name": "Kokulai East-MUL/78",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Karunaddukerny-MUL/79",
      "name": "Karunaddukerny-MUL/79",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kokkuthoduvai_south-MUL/80",
      "name": "Kokkuthoduvai south-MUL/80",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kokkuthoduvai_North-MUL/81",
      "name": "Kokkuthoduvai North-MUL/81",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kokkuthoduvai_Center-MUL/82",
      "name": "Kokkuthoduvai Center-MUL/82",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Semmalai_East-MUL/83",
      "name": "Semmalai East-MUL/83",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Semmalai-MUL/84",
      "name": "Semmalai-MUL/84",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Uppumavelli-MUL/85",
      "name": "Uppumavelli-MUL/85",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Alambil_North-MUL/86",
      "name": "Alambil North-MUL/86",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Alampil_south-MUL/87",
      "name": "Alampil south-MUL/87",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Silawaththai-MUL/88",
      "name": "Silawaththai-MUL/88",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Silawaththai_south-MUL/89",
      "name": "Silawaththai south-MUL/89",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kallappau_south-MUL/90",
      "name": "Kallappau south-MUL/90",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kallappau_north-MUL/91",
      "name": "Kallappau north-MUL/91",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Vannankulam-MUL/92",
      "name": "Vannankulam-MUL/92",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Mullaitivu_south-MUL/93",
      "name": "Mullaitivu south-MUL/93",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Selvapuram-MUL/94",
      "name": "Selvapuram-MUL/94",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kovil_kudiyiruppu-MUL/95",
      "name": "Kovil kudiyiruppu-MUL/95",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Manalkudiyiruppu-MUL/96",
      "name": "Manalkudiyiruppu-MUL/96",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Mullaitivu_town-MUL/97",
      "name": "Mullaitivu town-MUL/97",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Mullivaikkal_East-MUL/98",
      "name": "Mullivaikkal East-MUL/98",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Mullivaikkal_West-MUL/99",
      "name": "Mullivaikkal West-MUL/99",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Ambalavanan_Pokkanai-MUL/100",
      "name": "Ambalavanan Pokkanai-MUL/100",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Vattapalai-MUL/101",
      "name": "Vattapalai-MUL/101",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Keppapilava-MUL/102",
      "name": "Keppapilava-MUL/102",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Mulliyawalai_west-MUL/103",
      "name": "Mulliyawalai west-MUL/103",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Mulliyawalai_North-MUL/104",
      "name": "Mulliyawalai North-MUL/104",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Putharikadu-MUL/105",
      "name": "Putharikadu-MUL/105",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Mulliyawalai_Centre-MUL/106",
      "name": "Mulliyawalai Centre-MUL/106",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Mulliyavalai_South-MUL/107",
      "name": "Mulliyavalai South-MUL/107",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Mulliyavalai_East-MUL/108",
      "name": "Mulliyavalai East-MUL/108",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Thanniryoottu_West-MUL109/",
      "name": "Thanniryoottu West-MUL109/",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Mamoolai-MUL/110",
      "name": "Mamoolai-MUL/110",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Mathavalasingankulam-MUL/111",
      "name": "Mathavalasingankulam-MUL/111",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Thanniyoothu_East-MUL/112",
      "name": "Thanniyoothu East-MUL/112",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Neeravipiddi_East-MUL/113",
      "name": "Neeravipiddi East-MUL/113",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Neeravipiddi_West-MUL/114",
      "name": "Neeravipiddi West-MUL/114",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Gigrapuram-MUL/115",
      "name": "Gigrapuram-MUL/115",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kanukerny_East-MUL/116",
      "name": "Kanukerny East-MUL/116",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kanukerny_West-MUL/117",
      "name": "Kanukerny West-MUL/117",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kumarapuram-MUL/118",
      "name": "Kumarapuram-MUL/118",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kumulamunai_west-MUL/119",
      "name": "Kumulamunai west-MUL/119",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Thannimurippu-MUL/120",
      "name": "Thannimurippu-MUL/120",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kumulamunai_east-MUL/121",
      "name": "Kumulamunai east-MUL/121",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Kumulamunai_Centre-MUL/122",
      "name": "Kumulamunai Centre-MUL/122",
      "ds": "Maritimepattu"
    },
    {
      "value": "gn:Nawa_Gajabapura-Weli/01",
      "name": "Nawa Gajabapura-Weli/01",
      "ds": "Welioya"
    },
    {
      "value": "gn:Kalyanapura-Weli/02",
      "name": "Kalyanapura-Weli/02",
      "ds": "Welioya"
    },
    {
      "value": "gn:Ehetugaswewa-Weli/03",
      "name": "Ehetugaswewa-Weli/03",
      "ds": "Welioya"
    },
    {
      "value": "gn:Ethawetubewews-Weli/04",
      "name": "Ethawetubewews-Weli/04",
      "ds": "Welioya"
    },
    {
      "value": "gn:Janakapura-Weli/05",
      "name": "Janakapura-Weli/05",
      "ds": "Welioya"
    },
    {
      "value": "gn:Kinbbanwewa_Left-Weli/06",
      "name": "Kinbbanwewa Left-Weli/06",
      "ds": "Welioya"
    },
    {
      "value": "gn:Nikawewa_South-Weli/07",
      "name": "Nikawewa South-Weli/07",
      "ds": "Welioya"
    },
    {
      "value": "gn:Kinbbanwewa_Left-Weli/08",
      "name": "Kinbbanwewa Left-Weli/08",
      "ds": "Welioya"
    },
    {
      "value": "gn:Gajabapura-Weli/09",
      "name": "Gajabapura-Weli/09",
      "ds": "Welioya"
    },
    {
      "value": "gn:Kovivayal-KN/78",
      "name": "Kovivayal-KN/78",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Iyakkachchi-KN/79",
      "name": "Iyakkachchi-KN/79",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Mugavil-KN/80",
      "name": "Mugavil-KN/80",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Masar-KN/81",
      "name": "Masar-KN/81",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Soranpattu-KN/82",
      "name": "Soranpattu-KN/82",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Tharmakerny-KN/83",
      "name": "Tharmakerny-KN/83",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Pulopallai_East-KN/84",
      "name": "Pulopallai East-KN/84",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Mullaiyady-KN/85",
      "name": "Mullaiyady-KN/85",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Thampakamam-KN/86",
      "name": "Thampakamam-KN/86",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Pallai_town-KN/87",
      "name": "Pallai town-KN/87",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Pulopallai_west-KN/88",
      "name": "Pulopallai west-KN/88",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Allipalai-KN/89",
      "name": "Allipalai-KN/89",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Kachcharvely-KN/90",
      "name": "Kachcharvely-KN/90",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Arasarkerny-KN/91",
      "name": "Arasarkerny-KN/91",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Ittavil-KN/92",
      "name": "Ittavil-KN/92",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Mugamalai-KN/93",
      "name": "Mugamalai-KN/93",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Vemhodukemy-KN/94",
      "name": "Vemhodukemy-KN/94",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Kilaly-KN/95",
      "name": "Kilaly-KN/95",
      "ds": "Pachchilaipalli"
    },
    {
      "value": "gn:Kumarapuram-KN/43",
      "name": "Kumarapuram-KN/43",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Paranthan-KN/44",
      "name": "Paranthan-KN/44",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Umayalpuram-KN/45",
      "name": "Umayalpuram-KN/45",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Elephantpass-KN/46",
      "name": "Elephantpass-KN/46",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Thadduvankoddy-KN/47",
      "name": "Thadduvankoddy-KN/47",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Korakkankadhu-KN/48",
      "name": "Korakkankadhu-KN/48",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Uriyan-KN/49",
      "name": "Uriyan-KN/49",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Murasumoddai-KN/50",
      "name": "Murasumoddai-KN/50",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Kandawalai-KN/51",
      "name": "Kandawalai-KN/51",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Periyakulam-KN/52",
      "name": "Periyakulam-KN/52",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Kalmadunagar-KN/53",
      "name": "Kalmadunagar-KN/53",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Tharmapuram_West-KN/54",
      "name": "Tharmapuram West-KN/54",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Tharmapuram_East-KN/55",
      "name": "Tharmapuram East-KN/55",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Puliyampokkanai-KN/56",
      "name": "Puliyampokkanai-KN/56",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Punnaineeravi-KN/57",
      "name": "Punnaineeravi-KN/57",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Piramanthanaru-KN/58",
      "name": "Piramanthanaru-KN/58",
      "ds": "Kandavalai"
    },
    {
      "value": "gn:Vannerikulam-KN/01",
      "name": "Vannerikulam-KN/01",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Anaiviluthankulam-KN/02",
      "name": "Anaiviluthankulam-KN/02",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Kannakaipuram-KN/03",
      "name": "Kannakaipuram-KN/03",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Skandapurum-KN/04",
      "name": "Skandapurum-KN/04",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Akkarayankulam-KN/05",
      "name": "Akkarayankulam-KN/05",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Konavil-KN/06",
      "name": "Konavil-KN/06",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Ponnagar-KN/07",
      "name": "Ponnagar-KN/07",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Bharathipuram-KN/08",
      "name": "Bharathipuram-KN/08",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Malayalapuram-KN/09",
      "name": "Malayalapuram-KN/09",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Vivegananthungar-KN/10",
      "name": "Vivegananthungar-KN/10",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Krishnapuram-KN/11",
      "name": "Krishnapuram-KN/11",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Uthayanagar_East-KN/12",
      "name": "Uthayanagar East-KN/12",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Uthayanagar_West-KN/13",
      "name": "Uthayanagar West-KN/13",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Ambalkulam-KN/14",
      "name": "Ambalkulam-KN/14",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Selvanagar-KN/15",
      "name": "Selvanagar-KN/15",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Ananthapuram-KN/16",
      "name": "Ananthapuram-KN/16",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Thondamannagar-KN/17",
      "name": "Thondamannagar-KN/17",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Kanagambigaikulam-KN/18",
      "name": "Kanagambigaikulam-KN/18",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Ambalnagar-KN/19",
      "name": "Ambalnagar-KN/19",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Thiruvaiaru-KN/20",
      "name": "Thiruvaiaru-KN/20",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Thiruvaiaru_West-KN/21",
      "name": "Thiruvaiaru West-KN/21",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Ratnapuram-KN/22",
      "name": "Ratnapuram-KN/22",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Kilinochchi_town-KN/23",
      "name": "Kilinochchi town-KN/23",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Maruthanagar-KN/24",
      "name": "Maruthanagar-KN/24",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Pannankandy-KN/25",
      "name": "Pannankandy-KN/25",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Kanagapurum-KN/26",
      "name": "Kanagapurum-KN/26",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Thirunagar_South-KN/27",
      "name": "Thirunagar South-KN/27",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Thirunagar_North-KN/28",
      "name": "Thirunagar North-KN/28",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Kaneshapuram-KN/29",
      "name": "Kaneshapuram-KN/29",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Jeyanthinagar-KN/30",
      "name": "Jeyanthinagar-KN/30",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Periyaparanthan-KN/31",
      "name": "Periyaparanthan-KN/31",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Uruthirapuram_North-KN/32",
      "name": "Uruthirapuram North-KN/32",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Uruthirapuram_East-KN/33",
      "name": "Uruthirapuram East-KN/33",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Uruthirapuram_West-KN/34",
      "name": "Uruthirapuram West-KN/34",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Sivanagar-KN/35",
      "name": "Sivanagar-KN/35",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Uttupalam-KN/36",
      "name": "Uttupalam-KN/36",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Puthumurippu-KN/37",
      "name": "Puthumurippu-KN/37",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Vadakachchi-KN/38",
      "name": "Vadakachchi-KN/38",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Civic_Centre-KN/39",
      "name": "Civic Centre-KN/39",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Mayavanor-KN/40",
      "name": "Mayavanor-KN/40",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Ramanathapuram-KN/41",
      "name": "Ramanathapuram-KN/41",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Mavadiyamman-KN/42",
      "name": "Mavadiyamman-KN/42",
      "ds": "Karachchi"
    },
    {
      "value": "gn:Nallur-KN/59",
      "name": "Nallur-KN/59",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Alankerny-KN/60",
      "name": "Alankerny-KN/60",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Kollakurichchi-KN/61",
      "name": "Kollakurichchi-KN/61",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Cheddiyakurichchi-KN/62",
      "name": "Cheddiyakurichchi-KN/62",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Gnanimadam-KN/63",
      "name": "Gnanimadam-KN/63",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Madduvilnadu_East-KN/64",
      "name": "Madduvilnadu East-KN/64",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Pallikkudah-KN/65",
      "name": "Pallikkudah-KN/65",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Maddavilnadhu_West-KN/66",
      "name": "Maddavilnadhu West-KN/66",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Paramankirai-KN/67",
      "name": "Paramankirai-KN/67",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Gowtharimunai-KN/68",
      "name": "Gowtharimunai-KN/68",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Jeyapuram_North-KN/69",
      "name": "Jeyapuram North-KN/69",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Jeyapuram_South-KN/70",
      "name": "Jeyapuram South-KN/70",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Kariyalainagapaduvan-KN/71",
      "name": "Kariyalainagapaduvan-KN/71",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Pallavarayankadhu-KN/72",
      "name": "Pallavarayankadhu-KN/72",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Mulankavil-KN/73",
      "name": "Mulankavil-KN/73",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Nachchikaduh-KN/74",
      "name": "Nachchikaduh-KN/74",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Kiranchi-KN/75",
      "name": "Kiranchi-KN/75",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Ponnaveli-KN/76",
      "name": "Ponnaveli-KN/76",
      "ds": "Poonakary"
    },
    {
      "value": "gn:Iranaithevu-KN/77",
      "name": "Iranaithevu-KN/77",
      "ds": "Poonakary"
    }
  ]
}
