import get from 'lodash/get';

// mutation types
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_STATE = 'SET_AUTH_STATE';

// initial state
const state = {
  token: null,
  user: null,
};

// getters
const getters = {
  user: state => {
    return state.user;
  },
  token: state => {
    return state.token;
  },
  loggedIn: (state, getters) => {
    return !!getters.token;
  },
  authorizationToken: (state, getters) => {
    return getters.token ? getters.token : null;
  },
};

// actions
const actions = {
  async setLoginState({commit}, authData) {
    try {
      commit(SET_AUTH_STATE, authData);
    } catch (error) {
      // console.log(error);
    }
  },
};

// mutations
const mutations = {
  [SET_AUTH_STATE]: (state, authData) => {
    state.token = get(authData, 'signInUserSession.accessToken.jwtToken');
    state.user = authData.attributes;
  },
  [LOGOUT]: state => {
    state.user = null;
    state.token = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
