<template>
  <div
    class="border-b bg-top-bar border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
  >
    <div class="flex-1 min-w-0">
      <div class="py-2">
        <ol class="flex mx-auto space-x-4 w-full">
          <li class="flex">
            <div class="flex items-center">
              <router-link :to="{name: 'home'}" class="text-gray-400 hover:text-gray-500">
                <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span class="sr-only">Home</span>
              </router-link>
            </div>
          </li>
          <li v-for="page in pages" :key="page.name" class="flex">
            <div class="flex items-center">
              <svg
                class="flex-shrink-0 h-4"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <router-link
                :to="page.to"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                :aria-current="page.current ? 'page' : undefined"
                >{{ page.name }}</router-link
              >
            </div>
          </li>
        </ol>
      </div>
    </div>
    <div class="mt-4 flex sm:mt-0 sm:ml-4">
      <slot />
    </div>
  </div>
</template>

<script>
import {HomeIcon} from '@heroicons/vue/solid';

export default {
  props: ['pages'],
  components: {
    HomeIcon,
  },
};
</script>
