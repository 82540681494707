import {createStore} from 'vuex';
import VuexPersistence from 'vuex-persist';
import app from './modules/app';
import auth from './modules/auth';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['app', 'auth'],
});

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  track: true,
  modules: {
    app: app,
    auth: auth,
  },
  plugins: [vuexLocal.plugin],
});
