// mutation types
import {Auth} from 'aws-amplify';

export const SET_THEME = 'SET_THEME';
import {groups} from '../../views/Users/filters';

// initial state
const state = {
  theme: null,
};

// getters
const getters = {
  theme: state => {
    return state.theme;
  },
  groups: () => {
    return groups;
  },
  getGroup: async () => {
    const user = await Auth.currentAuthenticatedUser();
    return user?.signInUserSession?.accessToken?.payload['cognito:groups'];
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  [SET_THEME]: (state, theme) => {
    state.theme = theme;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
